import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import zenDeskCommands from '../../utilities/zendesk/zenDeskCommands'

/**
 * @function ZendeskLocationWatcher
 * - Watches the current path of the user and sends the information to zendesk to have accurate paths on the zendesk dashboard.
 *
 * @returns {null}
 */
export default function ZendeskLocationWatcher() {
  const location = useLocation()

  useEffect(() => {
    try {
      zenDeskCommands.updateLocation()
    } catch (err) {
      console.error(err)
    }
  }, [location])

  return null
}
