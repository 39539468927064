import React from 'react'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { get, isFunction } from 'lodash'
import { Redirect, withRouter } from 'react-router-dom'
import {
  getUserStatus,
  getListings,
  UpdateRememberMe,
  updateUserInfoObj,
} from '../../../ducks/reducers/main_reducer'
import ChunkerButton from '../../../utilities/ChunkerButton'
import { authenticate } from '../../../core/api'
import Tour from '../../tourWizard'
import DialogRouter from '../../../components/DialogRouter/index'
import LoggerHOC from '../../../HigherOrderComponents/Logger'

import { resetHeapIdentity, heapIdentify } from '../../../utilities/RegisterHeapEvent'
import { URLS } from '../../../core/urls'
import { UserContext } from '../../../context/UserContext'
import AppCheckbox from '../../../components/common/AppCheckbox'
import FullPageSpinner from '../../../components/FullPageSpinner'

const styles = () => ({
  submitButton: {
    padding: '9px 15px 6px 15px',
    color: '#fff',
    fontWeight: '600',
    fontSize: '14px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    lineHeight: 0,
    background: '#ed8b00',
    border: 'none',
    borderRadius: '4px',
    outline: 'none',
    '&:hover': {
      backgroundColor: '#ed8b00',
    },
  },
  backButton: {
    padding: '9px 15px 6px 15px',
    color: '#fff',
    fontWeight: '600',
    fontSize: '14px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    lineHeight: 0,
    background: '#968C83',
    border: 'none',
    borderRadius: '4px',
    outline: 'none',
    '&:hover': {
      backgroundColor: '#968C83',
    },
  },
  forgotPassword: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: '10px',
  },
})

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emial2: '',
      password: '',
      error: null,
      showTcs: false,
      rememberMe: true,
      open: false,
      redirect: false,
      showTourDialog: false,
      mostRecentTcObj: {},
      tourRequired: false,
    }
  }

  componentDidMount() {
    if (localStorage.getItem('email')) {
      let email = localStorage.getItem('email')
      if (email === 'null') {
        email = ''
        // Reset the Heap Analytics Identity
        resetHeapIdentity()
      } else {
        // Set the Heap Analytics Identity
        heapIdentify(email)
      }
      this.setState({
        email,
      })
    }
  }

  SignIn = () => {
    const data = JSON.stringify({
      password: this.state.password,
      rememberMe: this.state.rememberMe,
      username: this.state.email.trim(),
    })

    authenticate(data)
      .then(async () => {
        const { setupUserSession } = this.context
        if (isFunction(setupUserSession)) await setupUserSession()
        else
          console.error(
            new Error(
              `setupUserSession was expected to be a function during login but was instead of type: ${typeof setupUserSession}`,
            ),
          )
      })
      .catch((error) => {
        const objResponse = get(error, ['response', 'data'], {})
        const strError =
          objResponse.detail === 'Bad credentials'
            ? 'Email Address and password do not match'
            : objResponse.detail
        this.props.logger.error({
          key: 'SIGN_IN_FAIL',
          message: 'Sign in failed',
        })
        this.setState({
          error: strError,
        })
      })
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
    if (name === 'name') {
      const x = name.split(' ')
      const first = x[0]
      const last = x.slice(1, x.length)
      this.setState({
        firstName: first,
        lastName: last.length > 0 ? last.join(' ') : null,
      })
    }
  }

  handleCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.SignIn()
    }
  }

  clearInputs = () => {
    this.setState({
      email: '',
      email2: '',
      company: '',
      firstName: '',
      lastName: '',
      name: '',
      phone: '',
    })
  }

  toggleTourDialog = async () => {
    // if this was the first time the user has signed in the tour is required
    // then set flag in db so app knows tour is not required next time that user signs in
    // once tour diaglog has rendered, check if user is returning.
    await this.handleSetUserAsReturning()
    this.props.history.replace(
      get(this.props, ['location', 'state', 'redirect']) || URLS.onLoginSuccessRedirectPath,
    )
  }

  handleSetUserAsReturning = async () => {
    try {
      const isReturningUser = this.context.isReturningUser
      if (!isReturningUser) await this.context.updateUserAsReturningUser()
    } catch (err) {
      console.error(err)
    }
  }

  shouldShowTour = () => {
    const { user, isReturningUser, shouldShowTermsAndConditions, status } = this.context
    /**
     * If user has not specified that the tour should be hidden
     * is not a returning user within "appJson"
     * and have already accepted the terms of service.
     *
     * Show Dialog
     */
    return [
      !user?.hideTour || !isReturningUser,
      !shouldShowTermsAndConditions,
      status.isUserLoggedIn,
    ].every((b) => b)
  }

  tourIsRequiredOnLogin = () => !this.context.isReturningUser

  shouldRedirectImidiatlyOnLogin = () => {
    const { shouldShowTermsAndConditions } = this.context

    return [!shouldShowTour, !shouldShowTermsAndConditions].every((b) => b)
  }

  render() {
    const { classes } = this.props
    const { status, shouldShowTermsAndConditions, isReturningUser } = this.context
    const shouldShowTour = this.shouldShowTour()

    if (status.isInitializing) return <FullPageSpinner />

    if (!shouldShowTour && status.isUserLoggedIn && !shouldShowTermsAndConditions)
      return (
        <Redirect
          to={get(this.props, ['location', 'state', 'redirect']) || URLS.onLoginSuccessRedirectPath}
        />
      )

    return (
      <div style={this.props.containerStyle}>
        <DialogRouter
          isModalOpen={shouldShowTour}
          handleRequestClose={() => this.toggleTourDialog(false)}
          disableBackdropClick={this.tourIsRequiredOnLogin()}
        >
          <Tour
            history={this.props.history}
            handleRequestClose={this.handleSetUserAsReturning}
            hideCheckbox={!isReturningUser}
          />
        </DialogRouter>
        <div>
          <Typography variant="subtitle1" style={{ lineHeight: '28px', fontFamily: 'TG-Bold' }}>
            Login
          </Typography>
          <Typography
            variant="body1"
            className="link Login_DontHaveAnAccountLink"
            onClick={() => {
              this.props.history.push('/register')
            }}
            data-cyid="signin-link-create-account"
          >
            Don't have an account? Create one here.
          </Typography>
          {this.state.error ? (
            <div
              className="alert alert-danger"
              style={{ margin: '20px 0 0' }}
              data-cyid="signin-alert-error"
            >
              <p style={{ fontSize: '14px' }}>{this.state.error}</p>
            </div>
          ) : null}
        </div>
        <div className="Login_InputContainer">
          <div className="Login_Inputs">
            <TextField
              autoFocus
              label="Email"
              fullWidth
              value={this.state.email}
              defaultValue={this.state.email || ''}
              onChange={this.handleChange('email')}
              variant="outlined"
              style={{ marginBottom: '30px' }}
              error={this.state.error}
              id="email"
              data-cyid="signin-input-email"
            />

            <TextField
              label="Password"
              type="password"
              fullWidth
              onChange={this.handleChange('password')}
              variant="outlined"
              onKeyPress={(e) => this.handleEnter(e)}
              style={{ marginBottom: '30px' }}
              error={this.state.error}
              id="password"
              data-cyid="signin-input-password"
            />
            <div className={classes.forgotPassword}>
              <Typography
                variant="body1"
                className="link"
                onClick={() => {
                  this.props.history.push('/login/reset')
                }}
                data-cyid="signin-link-reset-password"
              >
                Forgot my password.
              </Typography>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <AppCheckbox
                checked={this.state.rememberMe}
                label="Remember me"
                onChange={({ target }) => this.setState({ rememberMe: target.checked })}
              />
            </div>
          </div>
        </div>
        <div className="Login_SigninButton">
          <div>
            <ChunkerButton
              className={classes.submitButton}
              onClick={() => this.SignIn()}
              data-cyid="signin-button-login"
            >
              <Typography variant="button">Login</Typography>
            </ChunkerButton>
          </div>
        </div>
      </div>
    )
  }
}

Login.contextType = UserContext

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  getUserStatus,
  getListings,
  UpdateRememberMe,
  updateUserInfoObj,
})(withStyles(styles)(withRouter(LoggerHOC(Login))))
