import { Box, Button, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

function LoggedOutButtons({ classes, handleLoginPress, handleSignupPress }) {
  return (
    <Grid item style={{ display: 'flex' }}>
      <Box>
        <Button id="login" onClick={handleLoginPress} className={classes.whiteNavButton}>
          Log In
        </Button>
      </Box>
      <Box>
        <Button id="createAccount" onClick={handleSignupPress} className={classes.orangeNavButton}>
          Sign Up
        </Button>
      </Box>
    </Grid>
  )
}

LoggedOutButtons.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleLoginPress: PropTypes.func.isRequired,
  handleSignupPress: PropTypes.func.isRequired,
}

export default LoggedOutButtons
