import { isNil } from 'lodash'
import {
  DEFAULT_ADORNMENT,
  PALLET_DESCRIPTOR,
  measurementAdornmentArea,
  measurementAdornmentAreas,
  measurementAdornmentSquared,
  perTimeDescriptors,
  PALLETS_DESCRIPTOR,
} from '../../core/constants'

import { formatCurrency } from '../numberFormats'

export function getPriceOrListingSpaceAdornment(
  isPalletPricing,
  isRawProperty,
  measurementUnitTypeId,
  isSpaceAdornment = false,
) {
  if (isPalletPricing) {
    if (isSpaceAdornment) return PALLETS_DESCRIPTOR
    return PALLET_DESCRIPTOR
  }
  if (isRawProperty) {
    if (isSpaceAdornment)
      return measurementAdornmentAreas[measurementUnitTypeId] ?? DEFAULT_ADORNMENT
    return measurementAdornmentArea[measurementUnitTypeId] ?? DEFAULT_ADORNMENT
  }
  return measurementAdornmentSquared[measurementUnitTypeId] ?? DEFAULT_ADORNMENT
}

/**
 * @description
 *  - Gives the correct format to a price per unit given a listing obj.
 *
 * @example
 *   formatListingPrice(
 *      pricePerUnit: 123,
 *      isPalletPricing: true,
 *      isRawProperty: true,
 *      measurementUnitTypeId: 1,
 *    )
 *    returns -> '123 palettes / month'
 *
 * @param {Object} listingObj
 *
 * @returns {string}
 *
 */

export default function formatListingPrice(
  pricePerUnit,
  isPalletPricing,
  isRawProperty,
  measurementUnitTypeId,
) {
  if (isNil(pricePerUnit) && isNil(measurementUnitTypeId) && !isPalletPricing)
    return DEFAULT_ADORNMENT

  return `${formatCurrency(pricePerUnit)} ${getPriceOrListingSpaceAdornment(
    isPalletPricing,
    isRawProperty,
    measurementUnitTypeId,
  )}${perTimeDescriptors.PER_MONTH}`
}
