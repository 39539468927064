/* eslint-disable react/no-danger */

import React from 'react'
import DOMPurify from 'dompurify'
import './terms.css'
import { sortBy } from 'lodash'
import { getTermsOfService } from '../../core/api'

class Terms extends React.Component {
  constructor() {
    super()
    this.state = {
      terms: '',
    }
  }

  componentDidMount() {
    getTermsOfService().then((tcsArray) => {
      const currentTermsOfServiceObj = sortBy(tcsArray, ['id'])[0]
      this.setState({
        terms: DOMPurify.sanitize(currentTermsOfServiceObj.content),
      })
    })
  }

  render() {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: this.state.terms }} />
      </div>
    )
  }
}

export default Terms
