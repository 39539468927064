import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends React.PureComponent {
  componentDidUpdate({ location: prevLocation }) {
    const { location: curLocation } = this.props
    if (curLocation !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default withRouter(ScrollToTop)
