const runHeapAnalytics =
  process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'qa'

export function registerHeapEvent(
  actionDescription,
  itemTracked,
  itemDescription,
) {
  return runHeapAnalytics
    ? window.heap.track(actionDescription, { [itemTracked]: itemDescription })
    : null
}

export function heapIdentify(userEmail) {
  return runHeapAnalytics ? window.heap.identify(userEmail) : null
}

export function resetHeapIdentity() {
  return runHeapAnalytics ? window.heap.resetIdentity() : null
}

export function addHeapUserProperties(data) {
  return runHeapAnalytics ? window.heap.addUserProperties(data) : null
}
