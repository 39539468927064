import { isFunction } from 'lodash'

const commandWebWidget = (...command) => {
  try {
    // ensure window.zE exists.
    if (isFunction(window.zE)) return window.zE(...command)
    // if window.zE is not a function at this point return null. All return values should always be checked before being used.
    return null
    // ensure zendesk does not create a breaking change, and if they do, console.error it.
  } catch (err) {
    console.error(err)
    return null
  }
}

/**
 * @object chatCommands
 *  - Returns methods to make actions for the zendesk chat.
 */
const zenDeskCommands = {
  /**
   * @function reauthenticateChat
   * - Call after login or a new session is called.
   */
  reauthenticateChat: () => {
    commandWebWidget('webWidget', 'chat:reauthenticate')
  },
  /**
   * @function logout
   * - Call wheen the user logs out to end the session currently active between zendesk
   */
  logout: () => {
    commandWebWidget('webWidget', 'logout')
  },
  /**
   * @function updateLocation
   * - Updates settings to send the current location to zendesk so Chunker Service Team can see what page the user is currently looking at.
   */
  updateLocation: () => {
    commandWebWidget('webWidget', 'updatePath', {
      url: window.location.href,
      title: window.location.href,
    })
  },
  /**
   * @function openChatWidget
   * - Commands the chat widget to an open status
   */
  openChatWidget: () => {
    commandWebWidget('webWidget', 'open')
  },
  /**
   * @function closeChatWidget
   * - Commands the chat widget to a closed state.
   */
  closeChatWidget: () => {
    commandWebWidget('webWidget', 'close')
  },
  /**
   * @function getSupportDepartment
   * - Grabs the Support deparment information to indicate online status.
   *
   * @returns {{
   *   id: number | string
   *   name: string
   *   status: string
   * } | null | undefined}
   */
  getSupportDepartment: () => commandWebWidget('webWidget:get', 'chat:department', 'Support'),
  /**
   * @function sendAskAboutThisListingMessage
   * - Commands the chat to send a message about the listing.
   */
  sendAskAboutThisListingMessage: () => {
    commandWebWidget(
      'webWidget',
      'chat:send',
      `Hello, I would like to ask about this listing. See listing here: ${window.location.href}`,
    )
  },
}

export default zenDeskCommands
