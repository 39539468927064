import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { MemoryRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

class RouterDialog extends React.Component {
  renderCloseIcon = () => {
    if (this.props.hideClose) {
      return null
    }

    return (
      <div
        className="Login_CloseIcon"
        style={{ backgroundColor: this.props.backgroundColor || 'white' }}
      >
        <IconButton
          onClick={this.props.handleRequestClose}
          style={this.props.disableBackdropClick ? { display: 'none' } : { padding: '3px' }}
          data-cyid="dialog-button-close"
        >
          <CloseIcon />
        </IconButton>
      </div>
    )
  }

  handleClose = (event, reason) => {
    const { disableBackdropClick = true, handleRequestClose } = this.props

    if (reason !== 'backdropClick') {
      handleRequestClose()
    } else if (!disableBackdropClick) {
      handleRequestClose()
    }
  }

  render() {
    const { children, fullScreen, fullWidth, isModalOpen, maxWidth = 'md' } = this.props
    return (
      <Dialog
        open={isModalOpen}
        onClose={this.handleClose}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        {this.renderCloseIcon()}
        <MemoryRouter>{React.cloneElement(children, { ...this.props })}</MemoryRouter>
      </Dialog>
    )
  }
}

export default withMobileDialog()(RouterDialog)
