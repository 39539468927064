import { useUserCTX } from '@chunker/user-ctx'
import React from 'react'
import TermsDialogBasic from './TermsDialogBasic'

export default function TermsAndConditions() {
  const {
    shouldShowTermsAndConditions,
    handleAcceptTermsAndConditions,
    handleDeclineTermsAndConditions,
    clearUserSession,
  } = useUserCTX()

  return shouldShowTermsAndConditions ? (
    <TermsDialogBasic
      clearUserSession={clearUserSession}
      handleSuccess={handleAcceptTermsAndConditions}
      handleDecline={handleDeclineTermsAndConditions}
    />
  ) : null
}
