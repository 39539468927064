import React from 'react'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'

export default function FooterItem({ children, ...props }) {
  const theme = useTheme()
  const smBreakPoint = theme.breakpoints.down('xs')
  const isSmlScreen = useMediaQuery(smBreakPoint)
  const isXXSmlScreen = useMediaQuery('(max-width: 350px)')

  return (
    <Grid
      item
      xs={isXXSmlScreen ? 12 : 6}
      sm="auto"
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '50px',
        alignItems: 'center',
        marginLeft: isSmlScreen ? null : theme.spacing(3),
        ...props.style,
      }}
    >
      {children}
    </Grid>
  )
}
