import DashboardIcon from '@material-ui/icons/Dashboard'
import FindPropertyIcon from '@material-ui/icons/LocationOn'
import FlagIcon from '@material-ui/icons/Flag'
import HomeIcon from '@material-ui/icons/Home'
import LicensesIcon from '@material-ui/icons/Assignment'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import TourIcon from '@material-ui/icons/Explore'
import WarehouseRequestsIcon from '@material-ui/icons/Queue'
import { URLS } from '../../core/urls'

const LIST_PROPERTY_KEY = 1

const listAuthRoutes = [
  {
    label: 'Search',
    subLinks: [
      {
        path: '/search',
        label: 'Search Warehouses',
        mobileIcon: <SearchIcon />,
        key: 'search',
      },
      {
        path: '/containers/search',
        label: 'Search Containers',
        mobileIcon: <SearchIcon />,
        key: 'searchContainers',
      },
    ],
  },
  {
    path: '/dashboard',
    label: 'Dashboard',
    mobileIcon: <DashboardIcon />,
    key: 'dashboard',
  },
  {
    path: URLS.propertiesPath,
    label: 'Properties',
    mobileIcon: <FlagIcon />,
    key: 'properties',
  },
  {
    path: URLS.warehousesAllPath,
    label: 'Listings',
    mobileIcon: <HomeIcon />,
    key: 'listings',
  },
  {
    path: '/licenses',
    label: 'Licenses',
    mobileIcon: <LicensesIcon />,
    key: 'licenses',
  },
  {
    path: '/publish_demand',
    label: 'Request a Warehouse',
    mobileIcon: <FindPropertyIcon />,
    key: 'requestWarehouse',
  },
  {
    path: '/search_demand',
    label: 'Warehouse Requests',
    mobileIcon: <WarehouseRequestsIcon />,
    key: 'searchDemand',
  },
]

const listRoutes = {
  mobile: [
    {
      path: '/search_demand',
      label: 'Current Warehouse Requests',
      mobileIcon: <TourIcon />,
    },
    {
      path: '/publish_demand',
      label: 'Request Space',
      mobileIcon: <FindPropertyIcon />,
    },
  ],
  desktop: [
    {
      path: '/search_demand',
      label: 'Current Warehouse Requests',
    },
    { path: '/publish_demand', label: 'Request A Warehouse' },
    { path: URLS.contactUsPath, label: 'Contact Us' },
  ],
}

export default {
  authRoutes: listAuthRoutes,
  routes: listRoutes,
  LIST_PROPERTY_KEY,
}
