import LogRocket from 'logrocket'
import React from 'react'
import { UserCTX as UserContext, UserCTXProvider } from '@chunker/user-ctx'
import {
  addHeapUserProperties,
  heapIdentify,
  resetHeapIdentity,
} from '../../utilities/RegisterHeapEvent'
import { zenDeskCommands } from '../../utilities/zendesk'
import {
  getAccountInformation,
  getCurrentUser,
  getTermsOfService,
  logUserOut,
  updateUserInfos,
} from '../../core/api'

const setupAppData = (userInfo) => {
  localStorage.clear()
  sessionStorage.clear()

  const userEmail = userInfo?.user?.email

  if (Array.isArray(window.dataLayer))
    window.dataLayer.push({
      chunker_user_id: userInfo?.user?.id,
      chunker_user_email: userEmail,
    })
  else
    console.error(
      new Error('window.dataLayer was not of type array when trying to log the user out.'),
    )

  localStorage.setItem('email', userEmail)

  // TODO: License wizard has been using this id (userInfo id). This is not a typo. Although, it should be looked at and fixed in the future.
  localStorage.setItem('user_id', userInfo?.id)

  heapIdentify(userEmail)
  addHeapUserProperties(userInfo)

  // we did not fail to retrieve the user, reauthenticate zendesk
  zenDeskCommands.reauthenticateChat()

  LogRocket.identify(userEmail, { email: userEmail })
}

const onUserSessionDestroy = () => {
  const usersEmail = localStorage.getItem('email')
  localStorage.clear()
  sessionStorage.clear()

  if (typeof usersEmail === 'string') localStorage.setItem('email', usersEmail)

  if (Array.isArray(window.dataLayer))
    window.dataLayer.push({
      chunker_user_id: null,
      chunker_user_email: null,
    })
  else
    console.error(
      new Error('window.dataLayer was not of type array when trying to log the user out.'),
    )

  resetHeapIdentity()
  zenDeskCommands.logout()
}

function UserContextProvider({ children }) {
  return (
    <UserCTXProvider
      onUserSessionCreate={setupAppData}
      onUserSessionDestroy={onUserSessionDestroy}
      getCurrentUser={getCurrentUser}
      getTermsOfService={getTermsOfService}
      logUserOut={logUserOut}
      putUserInfos={updateUserInfos}
      getAccount2={getAccountInformation}
    >
      {children}
    </UserCTXProvider>
  )
}

export { UserContext, UserContextProvider }
