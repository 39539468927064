/// /////////////////////////////////////////////////////////////////////////////

const MESSAGES = {
  // LOGGER DEFAULTS VALUE
  DEFAULT_WARNING: null,
  DEFAULT_SUCCESS: null,
  DEFAULT_ERROR: 'Unexpected error',
  DEFAULT_ALERT: null,
  DEFAULT_CONFIRM: null,

  RESET_PASSWORD_SUCCESS: null,
  RESET_PASSWORD_FAILED: null,

  FETCH_ROLE_SUCCESS: null,
  FETCH_ROLE_FAILED: null,
  SIGNUP_SUCCESS: null,
  SIGNUP_FAILED: null,
  ACTIVATE_ACCOUNT_SUCCESS: null,
  ACTIVATE_ACCOUNT_FAILED: null,
  UPDATE_USER_SUCCESS: null,
  UPDATE_USER_FAILED: null,
  CREATE_COMPANY_SUCCESS: null,
  CREATE_COMPANY_FAILED: null,
  FETCH_USER_INFO_SUCCESS: null,
  FETCH_USER_INFO_FAILED: null,
  FETCH_USER_COMPANY_SUCCESS: null,
  FETCH_USER_COMPANY_FAILED: null,
  CREATE_COMAPANY_SUCCESS: null,
  CREATE_COMAPANY_FAILED: null,
  CHANGE_COMPANY_FAILED: null,
  CHANGE_COMPANY_SUCCESS: null,
  CHANGE_PASSWORD_FAILED: null,
  COMPANY_AUTO_SAVE_SUCCESS: null,
  COMPANY_AUTO_SAVE_FAILED: null,
  UPDATE_USER_INFO_SUCCESS: null,
  UPDATE_USER_INFO_FAILED: null,
  LISTING_RESERVATION_SUCCESS: null,
  LISTING_RESERVATION_FAILED: null,
  CANCEL_RESERVATION_SUCCESS: null,
  CANCEL_RESERVATION_FAILED: null,
  CREATE_LICENSE_SUCCESS: null,
  CREATE_LICENSE_FAILED: null,
  AUTO_SAVE_LICENSE_SUCCESS: null,
  AUTO_SAVE_LICENSE_FAILED: null,
  CREATE_LICENSE_DOCUMENT_SUCCESS: null,
  CREATE_LICENSE_DOCUMENT_FAILED: null,
  GET_LICENSE_SUCCESS: null,
  GET_LICENSE_FAILED: null,
  DELETE_LICENSE_DOCUMENT_SUCCESS: null,
  DELETE_LICENSE_DOCUMENT_FAILED: null,
  AUTO_SAVE_ALTERATION_SUCCESS: null,
  AUTO_SAVE_ALTERATION_FAILED: null,
  START_SIGNAURE_SUCCESS: null,
  START_SIGNAURE_FAILED: null,
  CHECK_DOCUSIGN_AUTH_SUCCESS: null,
  CHECK_DOCUSIGN_AUTH_FAILED: null,
  CANCEL_SIGNAURE_SUCCESS: null,
  CANCEL_SIGNAURE_FAILED: null,
  CREATE_LICENSE_REQUEST_FAILED: null,
  SUBMIT_INFO_REQUEST_SUCCESS: 'Your request has sent successfully!',
  SUBMIT_SUPPORT_REQUEST_SUCCESS: 'Your support ticket has been successfully created!',
  FETCH_DYNAMIC_DOCUMENT_SUCCESS: null,
  FETCH_DYNAMIC_DOCUMENT_FAILED: null,
  NEW_ACCOUNT_ADDED_SUCCESS: 'Account has been added successfully!',
  NEW_ACCOUNT_ADDED_FAIL: null,
  FUNDING_SOURCE_VERIFIED: 'Micro-deposit amounts are correct. New funding source added.',
  START_STRIPE_SUCCESS: null,
  SET_USER_LICENSE_ROLE_SUCCESS: null,
  SET_USER_LICENSE_ROLE_FAILED: null,
  START_LICENSE_SIGNATURE_SUCCESS: null,
  START_LICENSE_SIGNATURE_FAILED: null,
  LICENSE_SIGN_SUCCESS: null,
  LICENSE_SIGN_FAILED: null,
}

/// /////////////////////////////////////////////////////////////////////////////

export default {
  messages: MESSAGES,
}
