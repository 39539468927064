const initialState = {
  count: 0,
}

const UPDATE_COUNT = 'UPDATE_COUNT'

export function updateCount(count) {
  return {
    type: UPDATE_COUNT,
    payload: count,
  }
}
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
  //    var listings = state.createListing
  switch (action.type) {
    case UPDATE_COUNT:
      return { ...state, count: action.payload }
    default:
      return state
  }
}
