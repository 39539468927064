import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { UserContextProvider } from './context/UserContext'
import App from './App'
import { unregister } from './registerServiceWorker'
import store from './store'
import ScrollToTop from './components/ScrollToTop'
import './index.css'

ReactDOM.render(
  <Provider store={store}>
    <UserContextProvider>
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </UserContextProvider>
  </Provider>,
  document.getElementById('root'),
)
unregister()
