import { isNumber } from 'lodash'

export function formatNumbersWithCommas(number) {
  if (isNumber(number) || number) {
    const _number = number.toString()
    const isNumberNegative = _number.substring(0, 1) === '-'
    const _strippedNumber = number.toString().replace(/[^0-9$.]/g, '')
    const _numberWithCommas = _strippedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `${isNumberNegative ? '-' : ''}${_numberWithCommas}`
  }
  return ''
}

export function formatCurrency(strNumber) {
  if (strNumber) {
    return `$${formatNumbersWithCommas(strNumber ? Number(strNumber).toFixed(2) : 0.0)}`
  }
  return '$0.00'
}

export function formatCurrencyWithoutDollarSign(strNumber) {
  if (isNumber(strNumber)) {
    return `${formatNumbersWithCommas(strNumber ? Number(strNumber).toFixed(2) : 0.0)}`
  }
  return null
}

export function hasDecimals(number) {
  return number % 1 !== 0
}
