import ExpandMore from '@material-ui/icons/ExpandMore'
import { Menu, MenuItem } from '@material-ui/core'
import { useUserCTX } from '@chunker/user-ctx'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ChunkerButton from '../../utilities/ChunkerButton'
import { registerHeapEvent } from '../../utilities/RegisterHeapEvent'
import { getListings } from '../../ducks/reducers/main_reducer'
import LoggerHOC from '../../HigherOrderComponents/Logger'
import DialogRouter from '../DialogRouter'
import TourWizard from '../../containers/tourWizard'
import { URLS } from '../../core/urls'
import includesYMSOrAdminRole from '../../utilities/ymsUtils/includesYMSOrAdminRole'
import goToYMSSite from '../../utilities/ymsUtils/goToYMSSite'

function UserMenu() {
  const history = useHistory()
  const {
    user,
    status: { isUserLoggedIn },
    roles,
    clearUserSession,
  } = useUserCTX()

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [showTourDialog, setShowTourDialog] = useState(false)

  const nickName = (() => {
    if (!isUserLoggedIn) return ''

    const { user: userInfos } = user ?? {}
    if (!userInfos) return ''

    let menuButtonName = userInfos?.displayName || userInfos?.firstName
    if (menuButtonName?.length > 15) menuButtonName = `${menuButtonName.slice(0, 16).trim()}...`

    return menuButtonName
  })()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEditProfilePress = () => {
    registerHeapEvent('Button Click', 'button_name', 'Account Settings')
    history.push('/profile-form')
    handleClose()
  }

  const handleNotificationSettingsPress = () => {
    registerHeapEvent('Button Click', 'button_name', 'Notification Settings')
    history.push('/profile-form?notificationSettings=true')
    handleClose()
  }

  const handleEditCompanyPress = () => {
    registerHeapEvent('Button Click', 'button_name', 'Company Details')
    history.push('/company')
    handleClose()
  }

  const handlePaidListingPress = () => {
    registerHeapEvent('Button Click', 'button_name', 'Paid Listings')
    history.push('/company?paidListing=true')
    handleClose()
  }

  const handleLogoutPress = async () => {
    registerHeapEvent('Button Click', 'button_name', 'Log Out')
    dispatch(getListings(null))
    // must come last
    await clearUserSession()
    window.location.href = URLS.loginPath
  }

  const handleNavigateToYMS = () => {
    registerHeapEvent('Button Click', 'button_name', 'Yard Management System')
    goToYMSSite()
  }

  const toggleTourDialog = () => {
    handleClose()
    setShowTourDialog(!showTourDialog)
  }

  return (
    <>
      <ChunkerButton
        onClick={handleMenu}
        customColor="white"
        icon={ExpandMore}
        text={
          <span
            style={{
              fontFamily: 'Saira-Condensed-Bold',
              fontSize: '14px',
              wordBreak: 'break-word',
            }}
          >
            {nickName || 'Menu'}
          </span>
        }
        style={{ padding: '0px 0px 12px' }}
        iconRight
        variant="text"
        data-cyid="user-menu-button-nickname"
      />
      <DialogRouter
        handleRequestClose={() => setShowTourDialog(false)}
        isModalOpen={showTourDialog}
      >
        <TourWizard history={history} hideCheckbox />
      </DialogRouter>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditProfilePress}>Account Settings</MenuItem>
        <MenuItem onClick={handleNotificationSettingsPress}>Notification Settings</MenuItem>
        <MenuItem onClick={handleEditCompanyPress}>Company Details</MenuItem>
        <MenuItem onClick={handlePaidListingPress}>Paid Listing Subscriptions</MenuItem>
        <MenuItem onClick={toggleTourDialog}>Show Tour</MenuItem>
        {includesYMSOrAdminRole(roles) && (
          <MenuItem onClick={handleNavigateToYMS}>Yard Management System</MenuItem>
        )}
        <MenuItem onClick={handleLogoutPress} data-cyid="user-menu-button-sign-out">
          Sign Out
        </MenuItem>
      </Menu>
    </>
  )
}

export default LoggerHOC(UserMenu)
