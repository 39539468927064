import { getZendeskJWTString } from '../../core/api'

export default function setupZenDeskChatAuthentication() {
  window.zESettings = {
    webWidget: {
      authenticate: {
        chat: {
          /**
           * @function jwtFn is required to be called for within both sections.
           *
           *  - authenticate.chat uses the jwtFn to make chat requests on mount.
           *
           *  - authenticate uses jwtFn for reinitializing when a user has logged in after the first session has failed.
           *
           * @param {function} callback
           */
          jwtFn: (callback) => {
            getZendeskJWTString()
              .then((jwt) => {
                callback(jwt)
              })
              .catch((err) => console.error(err))
          },
        },
      },
    },
  }
}
