/**
 * @description
 * - A temp function created to attach the top level items to the nested array contained within it.
 *
 * @param {{
 *   listingPropertyMeasurementUnitType: {id: number}
 *   listingUsePalletPricing,
 *   listingTitle,
 *   listingPropertyRawProperty,
 *   listingOfferItem: Array<object>
 * }}
 */
export default function attachListingValuesTOffers(offer) {
  const listingPropertyMeasurementUnitType = offer?.listingPropertyMeasurementUnitType
  const listingUsePalletPricing = offer?.listingUsePalletPricing
  const listingTitle = offer?.listingTitle
  const listingPropertyRawProperty = offer?.listingPropertyRawProperty

  const { listingOfferItems, ...rest } = offer
  return {
    ...rest,
    listingOfferItems: listingOfferItems?.map((listingOfferItem) => ({
      ...listingOfferItem,
      listingPropertyMeasurementUnitType,
      listingUsePalletPricing,
      listingTitle,
      listingPropertyRawProperty,
    })),
  }
}
