import axios from 'axios'
import { extractResult, getEncryptedConfig } from './utils'

const chunkerRequest = axios.create({ headers: { 'X-Test-header': 'test value' } })
chunkerRequest.defaults.headers.post['Content-Type'] = 'application/json'

export function standardDelete(url) {
  return chunkerRequest.delete(url).then(extractResult)
}

export function standardGet(url) {
  return chunkerRequest.get(url).then(extractResult)
}

export function standardPatch(url, data = null) {
  return !data
    ? chunkerRequest.patch(url).then(extractResult)
    : chunkerRequest.patch(url, data).then(extractResult)
}

export function standardPost(url, data = null) {
  return !data
    ? chunkerRequest.post(url).then(extractResult)
    : chunkerRequest.post(url, data).then(extractResult)
}

export function encryptedPost(url, data) {
  return chunkerRequest.post(url, data, getEncryptedConfig(data)).then(extractResult)
}

export function standardPut(url, data = null) {
  return !data
    ? chunkerRequest.put(url).then(extractResult)
    : chunkerRequest.put(url, data).then(extractResult)
}

export function encryptedPut(url, data) {
  return chunkerRequest.put(url, data, getEncryptedConfig(data)).then(extractResult)
}

export default chunkerRequest
