const headlineMapping = {
  display4: 'h4',
  display3: 'h3',
  display2: 'h2',
  display1: 'h1',
  headline: 'h1',
  title: 'h2',
  subheading: 'h3',
  body2: 'label',
  body1: 'p',
}

/** @constant userRoles The users roles that exist on the server. */
export const userRoles = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_DEVELOPER: 'ROLE_DEVELOPER',
  ROLE_RESTRICTED: 'ROLE_RESTRICTED',
  ROLE_SALES: 'ROLE_SALES',
  ROLE_SUPPORT: 'ROLE_SUPPORT',
  ROLE_TEST: 'ROLE_TEST',
  ROLE_USER: 'ROLE_USER',
  ROLE_YMS_CUSTOMER: 'ROLE_YMS_CUSTOMER',
  ROLE_YMS_STAFF: 'ROLE_YMS_STAFF',
  ROLE_YMS_SUPPORT: 'ROLE_YMS_SUPPORT',
}

/** @constant recordStates The Progress state set by the application. These IDs match specific IDs the server expects. */
export const recordStates = {
  IN_PROGRESS: 1,
  READY_TO_PUBLISH: 2,
  PUBLISHED: 3,
  LICENSED: 4,
  COMPLETE: 5,
  ARCHIVED: 6,
  PENDING_APPROVAL: 7,
  NOT_APPROVED: 8,
}

/** @constant licenseRecordStates The Progress state set by the application. These IDs match specific IDs the server expects. */
export const licenseRecordStates = {
  IN_PROCESS: 1,
  AWAITING_SIGNATURE: 2,
  INITIAL_FUNDING: 3,
  RECURRING_BILLING: 4,
  COMPLETED: 5,
  ARCHIVED: 6,
  CANCELED: 7,
  SIGNATURE_COMPLETE: 8,
  SIGNATURE_DECLINED: 9,
  INITIAL_FUNDING_COMPLETE: 10,
  FINAL_PAYMENT_RECEIVED: 11,
}

/** @constant licenseUserRoles The Progress state set by the application. These IDs match specific IDs the server expects. */
export const licenseUserRoles = {
  LICENSEE: 1,
  LICENSEE_BROKER_OR_PARTNER: 2,
  LICENSOR: 3,
  LICENSOR_BROKER_OR_PARTNER: 4,
  LICENSEE_SIGNATORY: 5,
  LICENSOR_SIGNATORY: 6,
}

/** @constant imageTypes The image Types that we store in the database */
export const imageTypes = {
  PROPERTY: 1,
  FLOOR_PLAN: 2,
  SITE: 3,
  SITE_PLAN: 4,
}

/** @constant infoRequestTypes The type of request the user makes when submitting a contact form.  */
export const infoRequestTypes = {
  WAITING_LIST: 1,
  CONTACT_ME: 2,
  SEE_WHAT_CHUNKER_CAN_DO_FOR_YOU: 3,
  DEMAND_REQUEST: 4,
  LICENSE_REQUEST: 5,
  EXIT_INTENT: 6,
  CONTAINER_STORAGE: 7,
  CONTAINER_SITE_INTEREST: 8,
  PARTNER_REQUEST: 9,
}

/** @constant propertyRecordState The progress that the users property is currently in.  */
export const propertyRecordState = {
  NEW: 1,
  IN_PROGRESS: 2,
  COMPLETE: 3,
}

/** @constant measurementUnitTypes The type of measurement E.G. Feet, Meters */
export const measurementUnitTypes = {
  IMPERIAL: 1,
  METRIC: 2,
}

/** @constant measurementAdornmentStandard gives the specifics of feet and meters */
export const measurementAdornmentStandard = {
  [measurementUnitTypes.IMPERIAL]: 'ft',
  [measurementUnitTypes.METRIC]: 'meters',
}

/** @constant measurementAdornmentSquared give the specifics of measurements that are squared */
export const measurementAdornmentSquared = {
  [measurementUnitTypes.IMPERIAL]: 'ft²',
  [measurementUnitTypes.METRIC]: 'meters²',
}

/** @constant  measurementAdornmentArea gives area based adornments such as Acre */
export const measurementAdornmentArea = {
  [measurementUnitTypes.IMPERIAL]: 'acre',
  [measurementUnitTypes.METRIC]: 'hectare',
}

/** @constant  measurementAdornmentAreas gives area based adornments such as Acres */
export const measurementAdornmentAreas = {
  [measurementUnitTypes.IMPERIAL]: 'acres',
  [measurementUnitTypes.METRIC]: 'hectares',
}

/** @constant temperatureDescriptiveTypes The adornment for temperature based inputs for */
export const temperatureDescriptiveTypes = {
  [measurementUnitTypes.IMPERIAL]: '°F',
  [measurementUnitTypes.METRIC]: '°C',
}

/** @constants PALLET_DESCRIPTOR describe the word used to describe pallet for pricing. */
export const PALLET_DESCRIPTOR = 'pallet'

/** @constants PALLETS_DESCRIPTOR describe the word used to describe pallet in its plural form */
export const PALLETS_DESCRIPTOR = `${PALLET_DESCRIPTOR}s`

/** @constant perTimeDescriptors Describes end adornment values that can be attached to values to describe per month etc.. */
export const perTimeDescriptors = {
  PER_YEAR: ' / year',
  PER_MONTH: ' / month',
}

/**
 * @constant subscriptionStripeKeys give the Stripe subscription key given the topHitsKey provided
 */
export const subscriptionStripeKeys = {
  A: 'A',
  B: 'B',
  Z: 'Z',
}

/** @constant subscriptionStripeTypes give the Stripe subscription type given the topHitsKey provided */
export const subscriptionStripeTypes = {
  A: 'Chunker Choice',
  B: 'Premier',
  Z: 'Every Day Promo',
}

/**
 * @property REQUIRED_COLOR: The color that will be displayed when the required inputs are not yet complete
 * @property SUB_OPTIMAL_COLOR: Required is finished but non optimal
 * @property OPTIMAL_COLOR: Optimal percent met
 * */
export const stagingColors = {
  REQUIRED_COLOR: '#D45B56',
  SUB_OPTIMAL_COLOR: '#ffc107',
  OPTIMAL_COLOR: '#28a745',
}

/**
 * @constants ASTERISK_COLOR
 * - The color displayed as for the required asterisk.
 */
export const ASTERISK_COLOR = '#FF0000'

export const DEFAULT_ADORNMENT = '(Unknown)'

export default {
  headlineMapping,
  recordStates,
  propertyRecordState,
  imageTypes,
  infoRequestTypes,
  measurementUnitTypes,
  stagingColors,
  temperatureDescriptiveTypes,
}
