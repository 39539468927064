import { userRoles } from '../../core/constants'

/**
 * @function includesYMSOrAdminRole
 * - Simply finds if the user is a yms role.
 *
 * @param {import('@chunker/user-ctx').Roles} roles
 */
export default function includesYMSOrAdminRole(roles) {
  const ymsRoles = [
    userRoles.ROLE_YMS_CUSTOMER,
    userRoles.ROLE_YMS_STAFF,
    userRoles.ROLE_YMS_SUPPORT,
    userRoles.ROLE_ADMIN,
  ]

  return ymsRoles.some((role) => roles[role])
}
