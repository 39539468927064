import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Menu from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import MobileMenus from '../login/Login_Drawer_Mobile'
import Login from '../../containers/auth/login'
import Constants from './constants'

const styles = {
  list: {
    width: 250,
  },
  listFull: {
    width: 'auto',
  },
}

class TemporaryDrawer extends React.Component {
  constructor() {
    super()
    this.state = {
      right: false,
      open: false,
      requestAccess: false,
    }
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  handleRequestClose = () => {
    this.setState({ open: false })
  }

  handleWaitingListOpen = () => {
    this.setState(
      {
        requestAccess: true,
      },
      () => {
        this.setState({
          open: true,
        })
      },
    )
  }

  render() {
    const { classes } = this.props

    return (
      <div>
        <Dialog open={this.state.open} onRequestClose={this.handleRequestClose} maxWidth="md">
          <Login
            handleRequestClose={this.handleRequestClose}
            requestAccess={this.state.requestAccess}
          />
        </Dialog>
        <IconButton
          className={classes.button}
          aria-label="Delete"
          onClick={this.toggleDrawer('right', true)}
        >
          <Menu style={{ color: 'white' }} />
        </IconButton>

        <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('right', false)}
            onKeyDown={this.toggleDrawer('right', false)}
          >
            <MobileMenus
              history={this.props.history}
              toggleDrawer={this.toggleDrawer}
              handleClickOpenLoginDialog={this.props.onLoginPress}
              handleClickOpenSignupDialog={this.props.onSignupPress}
              handleClickCloseLoginDialog={this.handleRequestCloseLoginDialog}
              Constants={Constants}
              handleWaitingListOpen={this.handleWaitingListOpen}
            />
          </div>
        </Drawer>
      </div>
    )
  }
}

export default withStyles(styles)(TemporaryDrawer)
