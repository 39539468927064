/* eslint-disable no-return-assign,react/no-unused-state,consistent-return */
// TODO: JVD: fix no return assign and no unused state errors
import 'date-fns'
// CSS Imports
import './App.css'
import './fonts.css'
import './dashboard-reset.css'
import 'react-select/dist/react-select.css'

import React, { Component } from 'react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'
import { Elements } from '@stripe/react-stripe-js'
import LogRocket from 'logrocket'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StatusCodes } from 'http-status-codes'
import { get } from 'lodash'
import { loadStripe } from '@stripe/stripe-js'
import red from '@material-ui/core/colors/red'
import setupLogRocketReact from 'logrocket-react'
import Footer from './components/footer/Footer'
import Nav from './components/nav/Nav'
import chunkerRequest from './services/chunkerRequest'
import router from './router'
import { setupZenDeskChatAuthentication } from './utilities/zendesk'
import ZendeskLocationWatcher from './components/ZendeskLocationWatcher'
import TermsAndConditions from './components/TermsAndConditions'

// FingerprintJs - We use this to get a fingerprint for a user.  The fingerprint will stay the same as long as the user is on the same device.  We can also use the date from "components" if needed in the future.
const Fingerprint2 = require('@fingerprintjs/fingerprintjs')

let userFingerprint = ''
chunkerRequest.defaults.headers.common.fingerprint = userFingerprint
const stripeKey = process.env.REACT_APP_STRIPE_API_KEY

const stripePromise = loadStripe(stripeKey)

const fingerPrintOptions = {
  excludes: {
    userAgent: true,
    language: true,
    doNotTrack: true,
    plugins: true,
    fonts: true,
    fontsFlash: true,
    enumerateDevices: true,
    pixelRatio: true,
  },
}
try {
  if (window.requestIdleCallback) {
    requestIdleCallback(() => {
      Fingerprint2.getPromise(fingerPrintOptions).then((components) => {
        const values = components.map((component) => component.value)
        userFingerprint = Fingerprint2.x64hash128(values.join(''), 31)
        chunkerRequest.defaults.headers.common.fingerprint = userFingerprint
        console.log(`userFingerprint:${userFingerprint}`)
        console.log(components)
      })
    })
  } else {
    setTimeout(() => {
      Fingerprint2.getPromise(fingerPrintOptions).then((components) => {
        const values = components.map((component) => component.value)
        userFingerprint = Fingerprint2.x64hash128(values.join(''), 31)
        chunkerRequest.defaults.headers.common.fingerprint = userFingerprint
        console.log(`userFingerprint:${userFingerprint}`)
        console.log(components)
      })
    }, 500)
  }
} catch (e) {
  console.log(`Unable to get fingerprint.  Error: ${e}`)
}

setupLogRocketReact(LogRocket)

if (process.env.REACT_APP_ENV === 'prod') {
  // Disable console logging
  console.log = () => {}
  console.debug = () => {}
  console.trace = () => {}

  LogRocket.init('chunker/chunker', {
    console: {
      shouldAggregateConsoleErrors: true,
    },
  })
} else if (process.env.REACT_APP_ENV === 'qa') {
  console.log('Initialilzing LogRocket Development/QA Mode')
  LogRocket.init('chunker/prod-bbut5')
}

const fontSizes = {
  sml: 14,
  reg: 16,
  lg: 18,
  xl: 24,
  xxl: 28,
}

const theme = createTheme({
  typography: {
    fontSizes,
    headerFonts: {
      bold: 'Saira-Condensed-Bold',
      semiBold: 'Saira-Condensed-Semi-Bold',
    },
    h4: {
      fontFamily: 'TG-Bold-Condensed-20',
      fontSize: '36px',
      color: '#424242',
      lineHeight: '46px',
      marginLeft: '0',
    },
    h3: {
      fontFamily: 'TG-Bold-Condensed-20',
      fontSize: '32px',
      color: '#424242',
      lineHeight: '36px',
      marginLeft: '0',
    },
    h2: {
      fontFamily: 'TG-Bold-Condensed-20',
      fontSize: '21px',
      color: '#424242',
      lineHeight: '26px',
      marginLeft: '0',
      letterSpacing: '0',
    },
    h1: {
      fontFamily: 'TG-Bold-Condensed-20',
      fontSize: '14px',
      color: '#424242',
      lineHeight: '18px',
      marginLeft: '0',
      letterSpacing: '0',
    },
    h5: {
      fontFamily: 'TG-Bold-Condensed-20',
      color: '#424242',
    },
    h6: {
      fontFamily: 'TG-Bold-Condensed-20',
      fontSize: '28px',
      color: '#424242',
      lineHeight: '28px',
    },
    subtitle1: {
      fontFamily: 'TG-Bold-Condensed-20',
      fontSize: '20px',
      color: '#424242',
      lineHeight: '20px',
    },
    subtitle2: {
      fontFamily: 'TG-Bold-Condensed-20',
      fontSize: '18px',
      color: '#424242',
      lineHeight: '21px',
    },
    body2: {
      fontFamily: 'TG-Bold-Condensed-20',
      fontSize: fontSizes.reg,
      color: '#424242',
      lineHeight: '18px',
    },
    body1: {
      fontFamily: 'forzaBook',
      fontSize: fontSizes.reg,
      color: '#424242',
      lineHeight: '18px',
    },
    caption: {
      // fontFamily: 'forzaBookItalic',
      fontSize: '12px',
      color: '#968C83',
      lineHeight: '18px',
    },

    button: {
      fontFamily: 'Saira-Condensed-Bold',
      fontSize: 15,
      lineHeight: '15px',
      color: '#fff',
      textTransform: 'uppercase',
      display: 'inline-block',
      boxShadow: 'none !important',
      borderRadius: '4 !important',
    },
  },
  overrides: {
    MuiAlert: {
      root: {
        fontFamily: 'Saira-Condensed-Bold',
      },
    },
  },
  palette: {
    primary: {
      main: '#ed8b00',
      dark: '#BB6D00',
      contrastText: '#fff',
    },
    secondary: {
      main: '#83786f',
      contrastText: '#fff',
    },
    chunkerBrown: {
      main: '#424242',
      contrastText: '#fff',
    },
    backgrounds: {
      main: '#eeeeee',
      light: '#F3F3F3',
      dark: '#D1D1D1',
    },
    black: {
      main: '#000000DE',
    },
    borders: {
      light: 'rgba(0, 0, 0, 0.2)',
    },
    contrast: '#fff',
    error: red,
    common: {
      grey: {
        extralight: '#F5F5F5',
        light: '#EEEEEE',
        main: '#E0E0E0',
        dark: '#BDBDBD',
        extradark: '#757575',
      },
    },
  },
  tooltip: {
    fontSize: '4000px',
  },
})

class App extends Component {
  constructor() {
    super()

    this.state = {
      isLoading: true,
    }

    const baseURL = process.env.REACT_APP_BASEURL
      ? process.env.REACT_APP_BASEURL
      : window.location.origin

    chunkerRequest.defaults.baseURL = baseURL
    chunkerRequest.defaults.withCredentials = true

    chunkerRequest.interceptors.response.use(
      (response) => response,
      (error) => {
        const strUrl = get(error, ['response', 'request', 'responseURL'], '')
        const strPath = strUrl.replace(baseURL, '')
        const intStatus = get(error, ['response', 'request', 'status'])
        const is404 = intStatus === StatusCodes.NOT_FOUND
        const is403 = intStatus === StatusCodes.FORBIDDEN

        // If the user came from a server redirect path but it was not found.
        if (strPath.includes('/api/paths/check') && is404) {
          window.location.href = '/'
          return
        }

        const isErrorAllowed =
          (strPath === ('/api/docusign/userinfo' || '/api/signing-envelopes2/create') && is403) ||
          (strPath.includes('/api/license-template2') && is404) ||
          (strPath.includes('/api/stripe/company') && is404)

        if (
          localStorage.getItem('user_id') &&
          !window.location.href.includes('/login') &&
          !window.location.href.includes('/error/') &&
          !isErrorAllowed
        ) {
          const accessDenied = error.toString().indexOf(StatusCodes.FORBIDDEN.toString())
          const notFound = error.toString().indexOf(StatusCodes.NOT_FOUND.toString())
          const unauthenticated = error.toString().indexOf(StatusCodes.UNAUTHORIZED.toString())

          // if the user came from a view listing which was not found.
          if (strPath.includes('/listing') && is404) {
            window.location.href = `/error/${StatusCodes.NOT_FOUND}/2`
            return
          }

          if (unauthenticated !== -1) {
            localStorage.clear()
            return (window.location.href = `/login?redirect-uri=${window.location.pathname}`)
          }
          if (notFound !== -1) {
            if (!window.location.href.includes('/error/')) {
              return (window.location.href = `/error/${StatusCodes.NOT_FOUND}/1`)
            }
          } else if (accessDenied !== -1) {
            if (!window.location.href.includes('/error/')) {
              return (window.location.href = `/error/${StatusCodes.FORBIDDEN}/1`)
            }
          }
        }

        return Promise.reject(error)
      },
    )

    setupZenDeskChatAuthentication()
  }

  render() {
    return (
      <div className="App">
        <CssBaseline />
        <ZendeskLocationWatcher />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={theme}>
            <TermsAndConditions />
            <Nav />
            <Elements stripe={stripePromise}>
              <div className="MainContent">{router}</div>
            </Elements>
            <Footer />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </div>
    )
  }
}

export default App
