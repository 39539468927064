// Internal Imports

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CreateAccountIcon from '@material-ui/icons/AccountCircle'
import Divider from '@material-ui/core/Divider'
import EventIcon from '@material-ui/icons/Event'
import HomeIcon from '@material-ui/icons/Home'
import EmailIcon from '@material-ui/icons/Email'
import IconButton from '@material-ui/core/IconButton'
import { Link, withRouter } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PersonIcon from '@material-ui/icons/Person'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { withStyles } from '@material-ui/core/styles'
import { isFunction } from 'lodash'
import { registerHeapEvent } from '../../utilities/RegisterHeapEvent'
import { getListings, getUserStatus } from '../../ducks/reducers/main_reducer'
import { UserContext } from '../../context/UserContext'
import { URLS } from '../../core/urls'
import includesYMSOrAdminRole from '../../utilities/ymsUtils/includesYMSOrAdminRole'
import goToYMSSite from '../../utilities/ymsUtils/goToYMSSite'
import windowOpenBlank from '../../utilities/windowOpenBlank'

const styles = (theme) => ({
  Link: {
    textDecoration: 'none',
  },
  ScheduleCallLink: {
    textDecoration: 'none',
    backgroundColor: theme.palette.primary.light,
  },
})

class LoginDialog extends React.Component {
  componentDidMount() {
    this.props.getUserStatus() // TODO: Refactor in order to use redux with the right flow
  }

  handleLogout = async () => {
    this.props.getListings(null)

    // must come last
    const { clearUserSession } = this.context
    if (isFunction(clearUserSession)) await clearUserSession()
    else
      console.error(
        new Error(
          `clearUserSession was expected to be a function to log the user out but was instead typeof: ${typeof clearUserSession}`,
        ),
      )

    window.location.href = URLS.loginPath
  }

  renderAuthMobileMenus = () => {
    const { classes, Constants } = this.props
    const { roles } = this.context
    const isYMSRoleOrAdmin = includesYMSOrAdminRole(roles)

    return (
      <div>
        <IconButton onClick={this.props.toggleDrawer('right', false)}>
          <ChevronRightIcon />
        </IconButton>
        <Divider />
        {Constants.authRoutes.map((routeObj) => {
          if (routeObj.subLinks) {
            return routeObj.subLinks.map((subLinkObj) => (
              <div>
                <List>
                  <Link
                    to={subLinkObj.path}
                    onClick={() =>
                      registerHeapEvent(
                        'Button Click',
                        'button_name',
                        `Mobile_Nav_${subLinkObj.label}`,
                      )
                    }
                    className={classes.Link}
                  >
                    <ListItem button>
                      <ListItemIcon>{subLinkObj.mobileIcon}</ListItemIcon>
                      <Typography variant="caption">{subLinkObj.label}</Typography>
                    </ListItem>
                  </Link>
                </List>
                <Divider />
              </div>
            ))
          }
          return (
            <div>
              <List>
                <Link
                  to={routeObj.path}
                  onClick={() =>
                    registerHeapEvent('Button Click', 'button_name', `Mobile_Nav_${routeObj.label}`)
                  }
                  className={classes.Link}
                >
                  <ListItem button>
                    <ListItemIcon>{routeObj.mobileIcon}</ListItemIcon>
                    <Typography variant="caption">{routeObj.label}</Typography>
                  </ListItem>
                </Link>
              </List>
              <Divider />
            </div>
          )
        })}
        <List>
          <Link
            to="/profile-form"
            className={classes.Link}
            onClick={() => registerHeapEvent('Button Click', 'button_name', 'Mobile_Nav_Profile')}
          >
            <ListItem button>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <Typography variant="caption">Profile</Typography>
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link
            to="/company"
            className={classes.Link}
            onClick={() => registerHeapEvent('Button Click', 'button_name', 'Mobile_Nav_Company')}
          >
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <Typography variant="caption">Company</Typography>
            </ListItem>
          </Link>
        </List>
        <Divider />
        {isYMSRoleOrAdmin && (
          <>
            <List>
              <div
                onClick={() => {
                  registerHeapEvent('Button Click', 'button_name', `Yard Management System`)
                  goToYMSSite()
                }}
                className={classes.Link}
              >
                <ListItem button>
                  <ListItemIcon>
                    <LocalShippingIcon />
                  </ListItemIcon>
                  <Typography variant="caption">Yard Management System</Typography>
                </ListItem>
              </div>
            </List>
            <Divider />
          </>
        )}
        <List
          className={classes.ScheduleCallLink}
          onClick={() =>
            registerHeapEvent('Button Click', 'button_name', `Mobile_Nav_Schedule_Call`)
          }
        >
          <ListItem button onClick={() => windowOpenBlank(URLS.leaveChunker.scheduleCall)}>
            <ListItemIcon>
              <EventIcon htmlColor="white" />
            </ListItemIcon>
            <Typography variant="caption" style={{ color: 'white' }}>
              Schedule a Call
            </Typography>
          </ListItem>
        </List>
        <List
          style={{ width: '200px' }}
          onClick={() => registerHeapEvent('Button Click', 'button_name', 'Mobile_Nav_Logout')}
        >
          <ListItem
            button
            onClick={() => {
              this.handleLogout()
            }}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <Typography variant="caption">Logout</Typography>
          </ListItem>
        </List>
      </div>
    )
  }

  renderMobileMenus = () => {
    const { classes } = this.props

    return (
      <div>
        <IconButton onClick={this.props.toggleDrawer('right', false)}>
          <ChevronRightIcon />
        </IconButton>
        <Divider />
        <List
          style={{ width: '200px' }}
          onClick={() => registerHeapEvent('Button Click', 'button_name', `Mobile_Nav_Login`)}
        >
          <ListItem
            button
            onClick={() => {
              this.props.handleClickOpenLoginDialog()
            }}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <Typography variant="caption">Login</Typography>
          </ListItem>
        </List>
        <Divider />
        <List
          style={{ width: '200px' }}
          onClick={() =>
            registerHeapEvent('Button Click', 'button_name', `Mobile_Nav_Create_Account`)
          }
        >
          <ListItem
            button
            onClick={() => {
              this.props.handleClickOpenSignupDialog()
            }}
          >
            <ListItemIcon>
              <CreateAccountIcon />
            </ListItemIcon>
            <Typography variant="caption">Sign Up</Typography>
          </ListItem>
        </List>
        <Divider />
        <List
          style={{ width: '200px' }}
          onClick={() => registerHeapEvent('Button Click', 'button_name', `Mobile_Nav_Contact_Us`)}
        >
          <ListItem button onClick={() => this.props.history.push(URLS.contactUsPath)}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <Typography variant="caption">Contact Us</Typography>
          </ListItem>
        </List>
        <List
          style={{ width: '200px' }}
          className={classes.ScheduleCallLink}
          onClick={() =>
            registerHeapEvent('Button Click', 'button_name', `Mobile_Nav_Schedule_Call`)
          }
        >
          <ListItem button onClick={() => windowOpenBlank(URLS.leaveChunker.scheduleCall)}>
            <ListItemIcon>
              <EventIcon htmlColor="white" />
            </ListItemIcon>
            <Typography variant="caption" style={{ color: 'white' }}>
              Schedule a Call
            </Typography>
          </ListItem>
        </List>
      </div>
    )
  }

  render() {
    const {
      status: { isUserLoggedIn },
    } = this.context

    if (isUserLoggedIn) return this.renderAuthMobileMenus()

    return <div style={{ maxWidth: '900px' }}>{this.renderMobileMenus()}</div>
  }
}

LoginDialog.contextType = UserContext

function mapStateToProps(state) {
  return state
}

export default withRouter(
  connect(mapStateToProps, { getUserStatus, getListings })(withStyles(styles)(LoginDialog)),
)
