import React from 'react'
import Grid from '@material-ui/core/Grid'
import Backdrop from '@material-ui/core/Backdrop'

import { makeStyles } from '@material-ui/core/styles'
import Spinner from './Spinner'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

function SpinnerContainer() {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '75vh' }}>
      <Spinner />
    </Grid>
  )
}

function FullPageSpinner({ showBackdrop }) {
  const classes = useStyles()
  return showBackdrop ? (
    <Backdrop className={classes.backdrop} open>
      <SpinnerContainer />
    </Backdrop>
  ) : (
    <SpinnerContainer />
  )
}

export default FullPageSpinner
