import React from 'react'
import MobileStepper from '@material-ui/core/MobileStepper'
import { withStyles } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import ChunkerButton from '../utilities/ChunkerButton'

const styles = () => ({
  buttonIcon: {
    position: 'relative',
    top: '7px',
  },
  button: {
    marginBottom: '7px',
  },
})

function Stepper(props) {
  const { classes, activeStep, numberOfSteps, fnHandleNext, fnHandleBack, backgroundColor } = props
  return (
    <MobileStepper
      style={{ padding: '8px', backgroundColor: backgroundColor || '#fff' }}
      variant="dots"
      steps={numberOfSteps}
      position="static"
      activeStep={activeStep}
      // className={classes.root}
      nextButton={
        <ChunkerButton
          size="small"
          onClick={fnHandleNext}
          disabled={activeStep === numberOfSteps - 1}
          className={classes.button}
        >
          Next
          <KeyboardArrowRight className={classes.buttonIcon} />
        </ChunkerButton>
      }
      backButton={
        <ChunkerButton
          size="small"
          onClick={fnHandleBack}
          disabled={activeStep === 0}
          className={classes.button}
        >
          <KeyboardArrowLeft className={classes.buttonIcon} />
          Back
        </ChunkerButton>
      }
    />
  )
}

export default withStyles(styles)(Stepper)
