import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../images/ChunkerLogoGray.png'
import '../../fonts.css'
import facebookIcon from '../../images/icons/facebook.png'
import linkedinIcon from '../../images/icons/linkedIn.png'
import { URLS } from '../../core/urls'
import windowOpenBlank from '../../utilities/windowOpenBlank'
import FooterItem from './FooterItem'

const useStyles = makeStyles((t) => ({
  root: {
    padding: '30px 60px',
  },
  footerContainer: {
    margin: 'auto',
    maxWidth: '1100px',
  },
  lightText: {
    fontFamily: 'TG-Condensed-18',
    color: t.palette.secondary.main,
    fontSize: '14px',
    lineHeight: '14px',
    userSelect: 'none',
    textDecoration: 'none',
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
      color: 'black',
    },
  },
  footerButtonText: {
    margin: '2px',
  },
  iconContainer: {
    '&:hover': {
      cursor: 'pointer',
      color: 'black',
    },
  },
  fabButtonImage: {
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    margin: t.spacing(1),
    borderRadius: '50%',
  },
  versionFont: {
    fontFamily: 'TG-Condensed-18',
    color: t.palette.common.grey.dark,
    fontSize: '16px',
  },
  logo: {
    fontFamily: 'TG-Condensed-18',
    color: '#847770',
    fontSize: '14px',
    userSelect: 'none',
    textDecoration: 'none',
  },
}))

function Footer() {
  const theme = useTheme()
  const smBreakPoint = theme.breakpoints.down('xs')
  const isXSScreen = useMediaQuery(smBreakPoint)
  const classes = useStyles()
  const { REACT_APP_VERSION_WATERMARK } = process.env
  const version = `${REACT_APP_VERSION_WATERMARK ?? 'REACT_APP_VERSION_WATERMARK'}`
  const hoverLinkClass = `${classes.lightText} ${classes.hover}`

  const chunkerCopyRight = `©Chunker ${new Date().getFullYear()}`

  const links = [
    {
      name: 'Terms of Service',
      url: URLS.termsAndServicesPath,
    },
    {
      name: 'Privacy Policy',
      url: URLS.privacyPolicyPath,
    },
    {
      name: 'Contact Us',
      url: URLS.contactUsPath,
    },
  ]

  const socialIcons = [
    {
      icon: linkedinIcon,
      url: URLS.leaveChunker.linkedin,
    },
    {
      icon: facebookIcon,
      url: URLS.leaveChunker.facebook,
    },
  ]

  return (
    <Box className={classes.root} style={{ backgroundColor: 'white' }}>
      <Box className={classes.footerContainer}>
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: theme.spacing(1.5),
            paddingLeft: theme.spacing(1.5),
          }}
        >
          {links.map(({ name, url }) => (
            <FooterItem key={name}>
              <Link className={`${hoverLinkClass} ${classes.footerButtonText}}`} to={url}>
                {name}
              </Link>
            </FooterItem>
          ))}
          <FooterItem style={{ display: 'flex' }}>
            {socialIcons.map(({ url, icon }) => (
              <div onClick={() => windowOpenBlank(url)} key={url} style={{ display: 'inline' }}>
                <img
                  color="secondary"
                  src={icon}
                  className={classes.fabButtonImage}
                  alt={`social_icon_${url}`}
                />
              </div>
            ))}
          </FooterItem>
          <FooterItem>
            <p className={classes.lightText}>{chunkerCopyRight}</p>
          </FooterItem>
          <FooterItem
            item={false}
            style={{ flex: 1, display: 'flex', justifyContent: isXSScreen ? 'center' : 'flex-end' }}
          >
            <p
              className={`${classes.lightText} ${classes.versionFont}`}
              style={{ whiteSpace: 'nowrap' }}
            >
              {version}
            </p>
          </FooterItem>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.lightText}
          style={{
            borderTop: '1px solid #847770',
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(1.5),
          }}
        />
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            style={{ cursor: 'pointer' }}
            onClick={() => windowOpenBlank(URLS.leaveChunker.wwwchunker)}
          >
            <img src={logo} alt="logo" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Footer
