import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Snack from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import MainStyle from './Style'

function Snackbar({ ...props }) {
  const { classes, message, color, icon, place, open } = props

  const action = [
    <IconButton
      className={classes.iconButton}
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={props.closeNotification}
    >
      <Close className={classes.close} />
    </IconButton>,
  ]

  const messageClasses = cx({
    [classes.iconMessage]: icon !== undefined,
  })

  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === 'info',
    [classes.successIcon]: color === 'success',
    [classes.warningIcon]: color === 'warning',
    [classes.dangerIcon]: color === 'danger',
    [classes.primaryIcon]: color === 'primary',
    [classes.roseIcon]: color === 'rose',
  })

  const style = props.isOnContainer ? { position: 'absolute' } : null

  return (
    <Snack
      anchorOrigin={{
        vertical: place.indexOf('t') === -1 ? 'bottom' : 'top',
        horizontal:
          place.indexOf('l') !== -1 ? 'left' : place.indexOf('c') !== -1 ? 'center' : 'right',
      }}
      open={open}
      message={
        <div>
          {icon !== undefined ? <props.icon className={iconClasses} /> : null}
          <span className={messageClasses}>{message}</span>
        </div>
      }
      action={action}
      style={style}
      ContentProps={{
        classes: {
          root: `${classes.root} ${classes[color]}`,
          message: classes.message,
        },
      }}
    />
  )
}

Snackbar.defaultProps = {
  color: 'info',
}

Snackbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary', 'rose']),
  // eslint-disable-next-line react/require-default-props
  close: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  place: PropTypes.oneOf(['tl', 'tr', 'tc', 'br', 'bl', 'bc']),
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
}

export default withStyles(MainStyle)(Snackbar)
