import QueryString from 'query-string'
import { get as lodashGet } from 'lodash'
import { extractResult, getEncryptedConfig } from '../services/utils'

import chunkerRequest from '../services/chunkerRequest'
import { infoRequestTypes } from './constants'

const { delete: deleteX, get: axiosGet, patch, post, put } = chunkerRequest
// TODO: Implement Axios resources or similar library.

export function authenticate(objData) {
  const strUrl = '/api/authenticate'
  console.log('authenticate fired')
  return post(strUrl, objData)
    .then(extractResult)
    .then((res) => {
      console.log(res)
      return res
    })
}

export function createUser(objData) {
  const strUrl = '/api/register2'
  return post(strUrl, objData)
}

export function createUserFromInvite(objValues, inviteKey) {
  const strUrl = `api/registerWithInvite?inviteKey=${inviteKey}`
  return post(strUrl, objValues).then(extractResult)
}

export function createUserFromMiniListing(objData, listingId) {
  const strUrl = `/api/register2?listingStartId=${listingId}`
  return post(strUrl, objData).then(extractResult)
}

export function resetPasswordInit(objData) {
  const objHeaders = {
    headers: {
      'Content-Type': 'text/plain',
    },
  }

  const strUrl = '/api/account/reset-password/init'
  return post(strUrl, objData, objHeaders)
}

export function resetPassword(objData) {
  const strUrl = '/api/account/reset-password/finish'
  return post(strUrl, objData)
}

export function changePassword(currentPassword, strPassword) {
  const payloadObj = {
    currentPassword,
    newPassword: strPassword,
  }

  const strUrl = '/api/account/change-password'
  return post(strUrl, payloadObj)
}

export function getUserRoles() {
  const strUrl = '/api/user-roles2'
  return axiosGet(strUrl).then(extractResult)
}

export function getCurrentUser() {
  const strUrl = '/api/user-infos/current-user'
  return axiosGet(strUrl, getEncryptedConfig('', true)).then(extractResult)
}

export function logUserOut() {
  const strUrl = '/api/logout'
  return post(strUrl).then(extractResult)
}

export function activateAccount(objData) {
  const strUrl = '/api/activate'
  return axiosGet(strUrl, { params: objData })
}

export function getUserCompanies() {
  const strUrl = '/api/companies2'
  return axiosGet(strUrl).then(extractResult)
}

export function createCompany(objData) {
  const strUrl = '/api/companies2'
  return post(strUrl, objData).then(extractResult)
}

export function updateCompany(objData) {
  const strUrl = '/api/companies2'
  return put(strUrl, objData).then(extractResult)
}

export function getCompanyById(intCompanyId) {
  const strUrl = `/api/companies2/${intCompanyId}`
  return axiosGet(strUrl).then(extractResult)
}

export function updateCompanyDiff(intCompanyId, objData) {
  return getCompanyById(intCompanyId).then((objCompany) => {
    const strUrl = '/api/companies2'
    const objNewCompany = { ...objCompany, ...objData }
    return put(strUrl, objNewCompany).then(extractResult)
  })
}

export function createUserRoleCompany(objData) {
  const strUrl = '/api/user-role-companies2'
  return post(strUrl, objData).then(extractResult)
}

export function updateUserInfos(objData) {
  const strUrl = '/api/user-infos'
  return put(strUrl, objData).then(extractResult)
}

export function getCurrentAccount() {
  const strUrl = '/api/account'
  return axiosGet(strUrl).then(extractResult)
}

export function updateAccount(objData) {
  const strUrl = '/api/account'
  return post(strUrl, objData).then(extractResult)
}

export function updateCurrentAccount(objData) {
  return getCurrentAccount().then((objCurrentAccount) => {
    const objPayload = { ...objCurrentAccount, ...objData }
    return updateAccount(objPayload)
  })
}

export function createUserRolesCompany(objData) {
  const strUrl = `/api/user-role-companies2`
  return post(strUrl, objData).then(extractResult)
}

export function getUserRolesCompany(objData) {
  const strParameters = QueryString.stringify(objData)
  const strUrl = `/api/user-role-companies2?${strParameters}`
  return axiosGet(strUrl).then(extractResult)
}

export function findOrCreateUserRoleCompany(objData) {
  return getUserRolesCompany(objData).then((listUserRoleCompany) => {
    if (listUserRoleCompany.length) {
      return lodashGet(listUserRoleCompany, [0], listUserRoleCompany)
    }

    const objPayload = {
      company: { id: objData.companyId },
      userRole: { id: objData.userRoleId },
    }

    // eslint-disable-next-line no-shadow
    return createUserRolesCompany(objPayload).then((listUserRoleCompany) =>
      lodashGet(listUserRoleCompany, [0], listUserRoleCompany),
    )
  })
}

export function sendVerificationCode() {
  const strUrl = '/api/user-infos/current-user/verifyPhone/start'
  return axiosGet(strUrl).then(extractResult)
}

export function submitVerificationCode(code) {
  const strUrl = `/api/user-infos/current-user/verifyPhone/finish/${code}`
  return axiosGet(strUrl).then(extractResult)
}

export function getCompanyEntityTypes() {
  const strUrl = 'api/entity-types'
  return axiosGet(strUrl).then(extractResult)
}

export function getTimePeriodTypes() {
  const strUrl = 'api/time-period-types2'
  return axiosGet(strUrl).then(extractResult)
}

export function getLeasePaymentTypes() {
  const strUrl = 'api/lease-payment-types2'
  return axiosGet(strUrl).then(extractResult)
}

export function getLeaseTypes() {
  const strUrl = 'api/lease-types2'
  return axiosGet(strUrl).then(extractResult)
}

export function createLicenses(intListingId) {
  const strUrl = `/api/licenses2/start/${intListingId}`
  return post(strUrl, {}).then(extractResult)
}

export function updateLicense(objData) {
  const strUrl = `/api/licenses2`
  return put(strUrl, objData).then(extractResult)
}

export function createLicenseDocument(objPayload) {
  const strUrl = `/api/license-documents`
  return post(strUrl, objPayload).then(extractResult)
}

export function deleteLicenseDocument(intDocumentId) {
  const strUrl = `/api/license-documents/${intDocumentId}`
  return deleteX(strUrl).then(extractResult)
}

export function deleteLicense(licenseId) {
  const strUrl = `/api/licenses2/${licenseId}`
  return deleteX(strUrl).then(extractResult)
}

export function getLicense(intLicenseId) {
  const strUrl = `/api/licenses2/${intLicenseId}`
  return axiosGet(strUrl).then(extractResult)
}

export function getAllLicenseUserRoles() {
  const strUrl = '/api/license-user-roles'
  return axiosGet(strUrl).then(extractResult)
}

export function updateAlteration(objPayload) {
  const strUrl = `/api/alterations`
  return put(strUrl, objPayload).then(extractResult)
}

export function deleteAlteration(intAlterationId) {
  const strUrl = `/api/alterations/${intAlterationId}`
  return deleteX(strUrl).then(extractResult)
}

export function getDynamicDocument(licenseId) {
  const strUrl = `/api/license-template2/view?licenseId=${licenseId}`
  return axiosGet(strUrl).then(extractResult)
}

export function downloadLicenseDocument(licenseId) {
  const strUrl = `/api/license-template2/pdfurl?licenseId=${licenseId}`
  return axiosGet(strUrl).then(extractResult)
}

export function downloadLicenseDocumentWithSignatures(licenseId) {
  const strUrl = `/api/licenses2/${licenseId}/signed-pdf-url`
  return axiosGet(strUrl).then(extractResult)
}

export function getLicenseRecordStates() {
  const strUrl = '/api/license-record-states'
  return axiosGet(strUrl).then(extractResult)
}

export function getBrokerages() {
  const strUrl = `/api/companies2/brokerages?size=1000`
  return axiosGet(strUrl).then(extractResult)
}

export function getCompanyBrokers(companyId) {
  const strUrl = `/api/companies2/${companyId}/members`
  return axiosGet(strUrl).then(extractResult)
}

export function getPropertyRecordStates() {
  const strUrl = '/api/property-record-states'
  return axiosGet(strUrl).then(extractResult)
}

export function createNewLicense(objPayload) {
  const strUrl = `/api/license-documents`
  return post(strUrl, objPayload).then(extractResult)
}

export function getProperty(intPropertyId) {
  const strUrl = `/api/properties2/${intPropertyId}`
  return axiosGet(strUrl).then(extractResult)
}

export function updateProperty(objPayload) {
  const strUrl = `/api/properties2`
  return put(strUrl, objPayload).then(extractResult)
}

export function updatePropertyWithApiData(objData, intPropertyId) {
  const intId = intPropertyId || objData.id
  return getProperty(intId).then((objProperty) => {
    const objPayload = { ...objProperty, ...objData }
    return updateProperty(objPayload)
  })
}

export function createNewLicenseDocument(label) {
  const objPayload = {
    documentTypeInfo: {
      id: 7,
    },
    licenseDocumentState: {
      id: 1,
    },
    title: label,
  }
  const strUrl = 'api/license-documents'
  return post(strUrl, objPayload).then(extractResult)
}

export function createNewDocument(licenseId, objPayload) {
  const _objPayload = {
    licenseDocumentState: { id: 1 },
    ...objPayload,
  }
  const strUrl = `/api/licenses2/${licenseId}/documents`
  return post(strUrl, _objPayload).then(extractResult)
}

export function updateLicenseDocument(objPayload) {
  const strUrl = '/api/license-documents'
  return put(strUrl, objPayload).then(extractResult)
}

export function addMasterLeaseDocument() {
  const objPayload = {
    documentTypeInfo: {
      id: 1,
    },
    licenseDocumentState: {
      id: 1,
    },
  }
  const strUrl = 'api/license-documents'
  return post(strUrl, objPayload).then(extractResult)
}

export function startLicenseSignature(objPayload) {
  const strParameters = QueryString.stringify(objPayload)
  const strUrl = `/api/signing-envelopes2/create?${strParameters}`
  return post(strUrl, {}).then(extractResult)
}

export function cancelLicenseSignature(intLicenseId) {
  const strUrl = `/api/licenses2/${intLicenseId}/sign/cancel`
  return post(strUrl).then(extractResult)
}

export function sendLicenseSignature(intEnvelopeId) {
  const strUrl = `/api/signing-envelopes2/${intEnvelopeId}/send`
  return axiosGet(strUrl).then(extractResult)
}

export function getEnvelopStatus(intEnvelopeId) {
  const strUrl = `/api/signing-envelopes2/${intEnvelopeId}`
  return axiosGet(strUrl).then(extractResult)
}

export function submitRequirement(infoRequestObj) {
  const strUrl = '/api/requirements'
  // THIS REMOVED ALL FALSY VALUES FROM PARAMS OBJECT
  // eslint-disable-next-line no-param-reassign
  Object.keys(infoRequestObj).forEach((key) => !infoRequestObj[key] && delete infoRequestObj[key])
  return post(strUrl, infoRequestObj, getEncryptedConfig(infoRequestObj))
}

export function getPaymentDueTypes() {
  const strUrl = '/api/payment-due-types'
  return axiosGet(strUrl).then(extractResult)
}

export function getCompanyUsers(companyId) {
  const strUrl = `/api/companies2/${companyId}/members`
  return axiosGet(strUrl).then(extractResult)
}

export function createSupportTicket(payloadObj) {
  const strUrl = `/api/support-requests2`
  return post(strUrl, payloadObj)
}

export function updateDockDoor(payloadObj) {
  const strUrl = '/api/dock-doors'
  return put(strUrl, payloadObj).then(extractResult)
}

export function getDockDoorConfigurations() {
  const strUrl = '/api/dock-door-location-types'
  return axiosGet(strUrl).then(extractResult)
}

export function getCreatedOnBehalfOf(companyId, assistantId) {
  const strUrl = `/api/companies2/${companyId}/assistants/${assistantId}/on-behalf-of-list`
  return axiosGet(strUrl).then(extractResult)
}

export function getAccountInformation() {
  const strUrl = '/api/account2'
  return axiosGet(strUrl).then(extractResult)
}

export function createContactMeRequest(objPayload) {
  const strUrl = '/api/info-requests2'
  const contactMeRequestTypeId = 2
  const _objPayload = {
    ...objPayload,
    infoRequestType: { id: contactMeRequestTypeId },
  }
  return post(strUrl, _objPayload)
}

export function createExitIntentRequest(objPayload) {
  const strUrl = '/api/info-requests2'
  const exitIntentType = 6
  const _objPayload = {
    ...objPayload,
    infoRequestType: { id: exitIntentType },
  }
  return post(strUrl, _objPayload)
}

export function createLicenseRequest(objPayload) {
  const strUrl = '/api/info-requests2'

  const _objPayload = {
    ...objPayload,
    infoRequestType: { id: 5 },
  }

  return post(strUrl, _objPayload)
}

export function postInfoRequestByType(objPayload, requestTypeId) {
  const requestTypes = Object.values(infoRequestTypes)
  if (!requestTypes.includes(requestTypeId))
    console.error(
      new Error(
        `requestTypeId should be ${requestTypes.join(', ')}. instead received ${requestTypeId}`,
      ),
    )

  const strUrl = '/api/info-requests2'
  const _objPayload = { ...objPayload, infoRequestType: { id: requestTypeId } }
  return post(strUrl, _objPayload)
}

export function getTermsOfService() {
  const strUrl = '/api/terms-and-conditions2'
  return axiosGet(strUrl).then(extractResult)
}

export function getResponsibleParties() {
  const strUrl = `/api/alteration-responsible-parties`
  return axiosGet(strUrl).then(extractResult)
}

export function getAlterationPaymentTypes() {
  const strUrl = `/api/alteration-payment-types`
  return axiosGet(strUrl).then(extractResult)
}

export function getAlterationResponsibleParties() {
  const strUrl = `/api/alteration-responsible-parties`
  return axiosGet(strUrl).then(extractResult)
}

export function createAlteration(payloadObj) {
  const payload = payloadObj || {}
  const strUrl = '/api/alterations'
  return post(strUrl, payload).then(extractResult)
}

export function getResponsiblePartiesForAlteration() {
  const strUrl = '/api/alteration-responsible-parties'
  return axiosGet(strUrl).then(extractResult)
}

export function findMemberObj(email) {
  const strUrl = `/api/members/find?email=${email}`
  return axiosGet(strUrl).then(extractResult)
}

export function createNewMember(licenseId, isLicenseeMember, memberObj) {
  const strUrl = `/api/licence2/${licenseId}/members?isLicenseeMember=${isLicenseeMember}`
  return post(strUrl, memberObj).then(extractResult)
}

export function changeMemberObj(objPayload) {
  const strUrl = '/api/members'
  return put(strUrl, objPayload).then(extractResult)
}

export function removeMember(licenseId, memberId) {
  const strUrl = `/api/license2/${licenseId}/members/${memberId}`
  return deleteX(strUrl).then(extractResult)
}

export function startLicenseSign(licenseId) {
  const strUrl = `/api/licenses2/${licenseId}/sign/start`
  return post(strUrl).then(extractResult)
}

export function getLicenseSigning(licenseId) {
  const strUrl = `/api/licenses2/${licenseId}/sign/url`
  return axiosGet(strUrl).then(extractResult)
}

export function getEstimateTypes() {
  const strUrl = '/api/estimate-types'
  return axiosGet(strUrl).then(extractResult)
}

export function setLicenseUserRole(intLicenseId, objRole) {
  const strUrl = `/api/licenses2/${intLicenseId}/licenseUserRole`
  return put(strUrl, objRole).then(extractResult)
}

export function getCompanyAccessInfo(companyId) {
  const strUrl = `/api/companies2/${companyId}/access`
  return axiosGet(strUrl).then(extractResult)
}

export function getCompanyMembers(companyId) {
  const strUrl = `/api/companies2/${companyId}/members`
  return axiosGet(strUrl).then(extractResult)
}

export function getSearchLimits() {
  const strUrl = '/api/search-limits'
  return axiosGet(strUrl).then(extractResult)
}

export function getAllPendingUserAccounts() {
  const strUrl = '/api/user-infos/pending-users'
  return axiosGet(strUrl).then(extractResult)
}

export function approvePendingUserAccount(userId) {
  const strUrl = `/api/account/approve-account?userId=${userId}`
  return post(strUrl).then(extractResult)
}

export function declinePendingUserAccount(userId) {
  const strUrl = `/api/account/reject-account?userId=${userId}`
  return deleteX(strUrl).then(extractResult)
}

export function getFireSprinklerTypes() {
  const strUrl = '/api/fire-sprinkler-types2'
  return axiosGet(strUrl).then(extractResult)
}

export function getAddressStates() {
  const strUrl = '/api/address-states'
  return axiosGet(strUrl).then(extractResult)
}

export function getSecurityTypes() {
  const strUrl = '/api/security-deposit-types'
  return axiosGet(strUrl).then(extractResult)
}

export function getRestroomTypes() {
  const strUrl = '/api/restroom-types2'
  return axiosGet(strUrl).then(extractResult)
}

export function getPropertyImages(propertyId) {
  const strUrl = `/api/properties2/${propertyId}/images?imageTypeId=1`
  return axiosGet(strUrl).then(extractResult)
}

export function getPropertyFloorPlanImages(propertyId) {
  const strUrl = `/api/properties2/${propertyId}/images?imageTypeId=2`
  return axiosGet(strUrl).then(extractResult)
}

export function getDockDoorTypes() {
  const strUrl = '/api/dock-door-types2'
  return axiosGet(strUrl).then(extractResult)
}

// export function deleteAlteration(intAlterationId) {
//   const strUrl = `/api/alterations/${intAlterationId}`;
//   return deleteX(strUrl).then(extractResult);

export function getMeasurementUnitTypes() {
  const strUrl = '/api/measurement-unit-types'
  return axiosGet(strUrl).then(extractResult)
}

export function getConstructionTypes() {
  const strUrl = '/api/construction-types2'
  return axiosGet(strUrl).then(extractResult)
}

export function getPropertyZoningTypes() {
  const strUrl = '/api/property-zoning-types'
  return axiosGet(strUrl).then(extractResult)
}

export function impersonateUser(login) {
  const strUrl = `/api/impersonate?login=${login}`
  return post(strUrl).then(extractResult)
}

export function createPropertyUnit(objPayload) {
  const strUrl = '/api/property-units'
  return post(strUrl, objPayload).then(extractResult)
}

export function updatePropertyUnit(objPayload) {
  const strUrl = '/api/property-units'
  return put(strUrl, objPayload).then(extractResult)
}

export function deletePropertyUnit(id) {
  const strUrl = `/api/property-units/${id}`
  return deleteX(strUrl).then(extractResult)
}

export function getPermittedUses() {
  const strUrl = '/api/permitted-uses'
  return axiosGet(strUrl).then(extractResult)
}

export function getDockDoorLeverTypes() {
  const strUrl = '/api/dock-door-leveler-types'
  return axiosGet(strUrl).then(extractResult)
}

export function getDockDoorBumperTypes() {
  const strUrl = '/api/dock-door-bumper-types'
  return axiosGet(strUrl).then(extractResult)
}

export function getColdStorageUnitType() {
  const strUrl = '/api/temperature-unit-types'
  return axiosGet(strUrl).then(extractResult)
}

export function getLightingType() {
  const strUrl = '/api/lighting-types2'
  return axiosGet(strUrl).then(extractResult)
}

export function getPowerServiceTypes() {
  const strUrl = '/api/power-service-types2'
  return axiosGet(strUrl).then(extractResult)
}

export function getHeatingTypes() {
  const strUrl = '/api/heating-types'
  return axiosGet(strUrl).then(extractResult)
}

export function getListCountries() {
  const strUrl = '/api/country-types/activated-sorted'
  return axiosGet(strUrl).then(extractResult)
}

export function getBlockedDomains() {
  const strUrl = '/api/blocked-domains'
  return axiosGet(strUrl).then(extractResult)
}

export function getSubscriptionTypes() {
  const strUrl = '/api/subscription-types'
  return axiosGet(strUrl).then(extractResult)
}

export function getLicenseTemplate(id) {
  const strUrl = `/api/license-templates/${id}`
  return axiosGet(strUrl).then(extractResult)
}

export function updateLicenseTemplate(templateObj) {
  const strUrl = '/api/license-templates'
  return put(strUrl, templateObj).then(extractResult)
}

export function isEmailBlocked(email) {
  const strUrl = `/api/blocked-domains/check-email/${email}`
  return axiosGet(strUrl).then(extractResult)
}

export function getLicenseTemplateTags() {
  const strUrl = '/api/license-template-tags'
  return axiosGet(strUrl).then(extractResult)
}

export function getLicenseTagCategories() {
  const strUrl = '/api/license-template-tag-categories'
  return axiosGet(strUrl).then(extractResult)
}

export function previewLicenseTemplate(templateId, licenseId) {
  const strUrl = `/api/license-template2/test?id=${templateId}&licenseId=${licenseId}`
  return axiosGet(strUrl).then(extractResult)
}

export function deleteLicenseTemplate(id) {
  const strUrl = `/api/license-templates/${id}`
  return deleteX(strUrl).then(extractResult)
}

export function createNewLicenseTemplate() {
  const strUrl = '/api/license-templates2'
  return post(strUrl).then(extractResult)
}

export function duplicateLicenseTemplate(id) {
  const strUrl = `/api/license-templates2?copyFromId=${id}`
  return post(strUrl).then(extractResult)
}

export function checkInLicense(id) {
  const strUrl = `/api/license-template2/check-in/${id}`
  return put(strUrl).then(extractResult)
}

export function checkOutLicense(id, newVersion = false) {
  const strUrl = `/api/license-template2/check-out/${id}?newVersion=${newVersion}`
  return put(strUrl).then(extractResult)
}

export function getVersionsOfLicenseTemplate(templateId, page, size) {
  const strUrl = `/api/license-templates2/versions/{id}?id=${templateId}&page=${page}&size=${size}`
  return axiosGet(strUrl).then((objResponse) => ({
    templates: objResponse.data,
    totalCount: objResponse.headers['x-total-count'],
  }))
}

export function getLicenseTemplates(licenseId) {
  const strUrl = `/api/license-templates2?licenseId=${licenseId}`
  return axiosGet(strUrl).then(extractResult)
}

export function terminateMonthToMonthLicense(licenseId, terminationDate) {
  const strUrl = `/api/licenses2/${licenseId}/terminate?terminateAt=${terminationDate}`
  return post(strUrl).then(extractResult)
}

export function sendPromotions() {
  const strUrl = '/api/notification/process-promos'
  return post(strUrl).then(extractResult)
}

export function patchProperty(id, payload) {
  const strUrl = `/api/properties2/${id}`
  return patch(strUrl, payload).then(extractResult)
}

export function patchLicense(id, payload) {
  const strUrl = `/api/licenses2/${id}`
  return patch(strUrl, payload).then(extractResult)
}

export function patchCompany(id, payload) {
  const strUrl = `api/companies2/${id}`
  return patch(strUrl, payload).then(extractResult)
}

export function addPropertyImage(propertyId, payload) {
  const strUrl = `/api/properties2/${propertyId}/add-image`
  return post(strUrl, payload).then(extractResult)
}

export function deletePropertyImage(propertyId, imageId) {
  const strUrl = `/api/properties2/${propertyId}/remove-image/${imageId}`
  return deleteX(strUrl).then(extractResult)
}

export function addPropertyBroker(propertyId, brokerId) {
  const strUrl = `/api/properties2/${propertyId}/add-broker/${brokerId}`
  return post(strUrl).then(extractResult)
}

export function removePropertyBroker(propertyId, brokerId) {
  const strUrl = `/api/properties2/${propertyId}/remove-broker/${brokerId}`
  return deleteX(strUrl).then(extractResult)
}

export function setDefaultPropertyImg(propertyId, imgId) {
  const strUrl = `/api/properties2/${propertyId}/thumb-image/${imgId}`
  return put(strUrl).then(extractResult)
}

export function startVerifyTwilio(channel, address) {
  const strUrl = `/api/twilio/verify/start?channel=${channel}&to=${address}`
  return post(strUrl, '', getEncryptedConfig('', true)).then(extractResult)
}

export function finishVerifyTwilio(code, channel, address) {
  const strUrl = `/api/twilio/verify/finish?channel=${channel}&code=${code}&to=${address}`
  return post(strUrl, '', getEncryptedConfig('', true)).then(extractResult)
}

export function createRequirementInterest(payload) {
  const strUrl = '/api/requirement-interests'
  return post(strUrl, payload, getEncryptedConfig(payload)).then(extractResult)
}

export function addPropertyUnitToProperty(propertyId, propertyUnit) {
  const strUrl = `/api/properties2/${propertyId}/add-unit`
  return post(strUrl, propertyUnit).then(extractResult)
}

export function createNewProperty(propertyObj) {
  const strUrl = '/api/properties2'
  return post(strUrl, propertyObj).then(extractResult)
}

export function logIncomingPath(incomingPath) {
  const strUrl = `/api/paths/check?incoming=${incomingPath}`
  return axiosGet(strUrl, getEncryptedConfig(incomingPath, true)).then(extractResult)
}

// Box Site start

export function createBoxSite(propertyObj) {
  const strUrl = `/api/box-sites`
  return post(strUrl, propertyObj).then(extractResult)
}

export function getBoxSite(boxSiteId) {
  const strUrl = `api/box-sites/${boxSiteId}`
  return axiosGet(strUrl).then(extractResult)
}

export function getBoxSiteImageByType(boxSiteId, imageTypeId) {
  const strUrl = `api/box-sites/${boxSiteId}/images?imageTypeId=${imageTypeId}`
  return axiosGet(strUrl).then(extractResult)
}

export function postBoxSiteImage(boxSiteId, objPayload) {
  const strUrl = `/api/box-sites/${boxSiteId}/add-image`
  return post(strUrl, objPayload).then(extractResult)
}

export function deleteBoxSite(boxSiteId) {
  const strUrl = `/api/box-sites/${boxSiteId}`
  return deleteX(strUrl).then(extractResult)
}

export function deleteBoxSiteImage(boxSiteId, imageId) {
  const strUrl = `/api/box-sites/${boxSiteId}/remove-image/${imageId}`
  return deleteX(strUrl).then(extractResult)
}

export function putDefaultBoxSiteImage(boxSiteId, imageId) {
  const strUrl = `/api/box-sites/${boxSiteId}/thumb-image/${imageId}`
  return put(strUrl).then(extractResult)
}

export function patchBoxSite(boxSiteId, objPayload) {
  const strUrl = `api/box-sites/${boxSiteId}`
  return patch(strUrl, objPayload).then(extractResult)
}

export function getSiteImages(boxSiteId) {
  const strUrl = `/api/box-sites/${boxSiteId}/images?imageTypeId=3`
  return axiosGet(strUrl).then(extractResult)
}

export function getSitePlanImages(boxSiteId) {
  const strUrl = `/api/box-sites/${boxSiteId}/images?imageTypeId=4`
  return axiosGet(strUrl).then(extractResult)
}

export function getAllUsersBoxSites() {
  const strUrl = '/api/_search/box-sites?query=*'
  return axiosGet(strUrl).then(extractResult)
}

// container service

export function createBoxSiteService(boxSiteId, objPayload) {
  const strUrl = `/api/box-sites/${boxSiteId}/add-service`
  return post(strUrl, objPayload).then(extractResult)
}

export function patchBoxSiteService(serviceId, objPayload) {
  const strUrl = `/api/box-site-added-services/${serviceId}`
  return patch(strUrl, objPayload).then(extractResult)
}

export function deleteBoxSiteService(boxSiteId, serviceListingId) {
  const strUrl = `/api/box-sites/${boxSiteId}/delete-service/${serviceListingId}`
  return deleteX(strUrl, { id: serviceListingId }).then(extractResult)
}

/* Box sites Record State */
export function getBoxSitesRecordStates() {
  const strUrl = `/api/box-site-record-states`
  return axiosGet(strUrl).then(extractResult)
}

/** Box site assets */

export function addBoxSiteAssetEntry(boxSiteId, payload) {
  const strUrl = `/api/box-sites/${boxSiteId}/add-asset-entry`
  return post(strUrl, payload).then(extractResult)
}

export function removeBoxSiteAsset(boxSiteId, assetId) {
  const strUrl = `/api/box-sites/${boxSiteId}/delete-asset-entry/${assetId}`
  return deleteX(strUrl)
}

export function patchBoxSiteAsset(assetId, payload) {
  const strUrl = `/api/box-site-asset-entries/${assetId}`
  return patch(strUrl, payload)
}

export function fetchBoxSiteAssets() {
  const strUrl = `/api/box-site-assets`
  return axiosGet(strUrl).then(extractResult)
}

/** @description Does not extract results */
export function getZendeskJWTString() {
  const strUrl = '/api/zendesk/jwt'
  return axiosGet(strUrl).then(extractResult)
}
