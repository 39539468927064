import { useUserCTX } from '@chunker/user-ctx'
import React from 'react'
import { Route, Redirect } from 'react-router-dom'

function PrivateRoute({ unathorizedPathname, ...props }) {
  const {
    status: { isUserLoggedIn, isInitializing, isUserLoggedOut },
  } = useUserCTX()

  if (isInitializing) return null
  if (isUserLoggedIn) return <Route {...props} />

  return (
    <Redirect
      to={
        /**
         * Usually a user is logged out before we redirect or make the next action.
         * To prevent the redirect state from being flooded with redirect urls we will first check if they are logged out
         * ? A logged out user is a user is a user that was logged in, and is now logged out. A user that enters the site and was never logged in is known as authentication failed.
         * When a user logs in we check the redirect state to figure out if the user was trying to view an authenticated site before
         */
        isUserLoggedOut
          ? unathorizedPathname || '/login'
          : {
              pathname: unathorizedPathname || '/login',
              state: { redirect: props.location.pathname },
            }
      }
    />
  )
}

export default PrivateRoute
