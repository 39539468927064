import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import PrivateRoute from './components/PrivateRoute'
import Spinner from './components/Spinner'
import { URLS } from './core/urls'
import WithTracker from './HigherOrderComponents/WithTracker'

const Admin = lazy(() => import('./containers/Admin'))
const AllLicenses = lazy(() => import('./containers/AllLicenses/index'))
const Auth = lazy(() => import('./containers/auth'))
const BoxSiteEdit = lazy(() => import('./containers/BoxSitesEdit'))
const BoxSites = lazy(() => import('./containers/BoxSites'))
const BoxSitesView = lazy(() => import('./containers/BoxSitesView'))
const CompanyLookup = lazy(() => import('./containers/Admin/CompanyLookup'))
const CompanyPage = lazy(() => import('./containers/Company'))
const ContactPage = lazy(() => import('./components/Landing/ContactPage'))
const ContactSupport = lazy(() => import('./containers/ContactSupport'))
const Dashboard = lazy(() => import('./containers/dashboard'))
const DealNegotiation = lazy(() => import('./containers/DealNegotiation'))
const ErrorPage = lazy(() => import('./containers/ErrorPage'))
const ExpiredListingDetail = lazy(() => import('./containers/ExpiredTokenForListingDetailPage'))
const LicensingWizard = lazy(() => import('./containers/LicensingWizard'))
const List = lazy(() => import('./containers/AllListings'))
const ListingSales = lazy(() => import('./components/ListingSales'))
const ListingView = lazy(() => import('./containers/ListingView'))
const ProfileForm = lazy(() => import('./containers/profile/form'))
const PropertyViewPage = lazy(() => import('./containers/AllProperties'))
const PublishDemand = lazy(() => import('./containers/PublishDemand'))
const PublishDemandSuccess = lazy(() => import('./containers/PublishDemandSuccess'))
const RedirectHome = lazy(() => import('./components/RedirectHome'))
const ReduxListingForm = lazy(() => import('./containers/ListingForm'))
const ReduxPropertyForm = lazy(() => import('./containers/PropertyForm'))
const SearchChunker = lazy(() => import('./containers/SearchChunker'))
const SearchDemand = lazy(() => import('./containers/SearchDemand'))
const StripeAuth = lazy(() => import('./components/StripeValidate'))
const TermsFullPage = lazy(() => import('./components/TermsAndConditions/TermsFullPage'))
const Tour = lazy(() => import('./containers/tourWizard/FullPage'))
const UnsubscribeListing = lazy(() => import('./components/unsubscribeListing'))
const UserActivate = lazy(() => import('./containers/auth/user-activate'))
const UserLookup = lazy(() => import('./containers/Admin/UserLookup'))
const UserSubscriptionManagement = lazy(() => import('./containers/Admin/UserSubscriptions'))

function LoadingMessage() {
  return (
    <Grid container alignItems="center" justifyContent="center" style={{ height: '70vh' }}>
      <Spinner />
    </Grid>
  )
}

export default (
  <Suspense fallback={<LoadingMessage />}>
    <Switch>
      {/* ------------------ NO AUTH ------------------*/}
      <Route exact path="/" component={RedirectHome} />
      <Route path={URLS.contactUsPath} component={WithTracker(ContactPage)} />
      <Route path="/terms" component={WithTracker(TermsFullPage)} />
      <Route path="/(login|register)" component={WithTracker(Auth)} />
      <Route path="/activate" component={WithTracker(UserActivate)} />
      <Route path="/support" component={WithTracker(ContactSupport)} />
      <Route path="/expired_listing_detail" component={WithTracker(ExpiredListingDetail)} />
      <Route path="/unsubscribe_listing" component={WithTracker(UnsubscribeListing)} />
      <Route path="/stripe/validate" component={WithTracker(StripeAuth)} />
      <Route path="/error/:errorCode/:key" component={WithTracker(ErrorPage)} />

      {/* ------------------ TOKEN NEEDED ------------------*/}
      <Route path={URLS.warehousesViewPath(':id')} component={WithTracker(ListingView)} />

      {/* ------------------ AUTH ------------------*/}
      <PrivateRoute path={URLS.warehousesAllPath} component={WithTracker(List)} />
      <PrivateRoute
        path={`(${URLS.warehousesSearchPath}|${URLS.boxSitesSearchPath})`}
        component={WithTracker(SearchChunker)}
      />
      <PrivateRoute path="/sales/:id" component={WithTracker(ListingSales)} />
      <PrivateRoute path={URLS.boxSitePath(':id')} component={BoxSitesView} />
      <PrivateRoute path={URLS.boxSiteEditPath(':id')} component={BoxSiteEdit} />
      <PrivateRoute path={URLS.boxSitesPath} component={BoxSites} />
      <PrivateRoute path="/properties" component={WithTracker(PropertyViewPage)} />
      <PrivateRoute path="/profile-form" component={WithTracker(ProfileForm)} />
      <PrivateRoute path="/dashboard" component={WithTracker(Dashboard)} />
      <PrivateRoute path="/licensing-wizard/:id?" component={WithTracker(LicensingWizard)} />
      <PrivateRoute path="/licenses" component={WithTracker(AllLicenses)} />
      <PrivateRoute path="/admin" component={WithTracker(Admin)} />
      <PrivateRoute path="/company" component={WithTracker(CompanyPage)} />
      <PrivateRoute path="/tour" component={WithTracker(Tour)} />
      <PrivateRoute
        path={URLS.propertyEditPath(':id')}
        component={WithTracker(ReduxPropertyForm)}
      />
      <PrivateRoute path="/edit/:id" component={WithTracker(ReduxListingForm)} />
      <PrivateRoute
        path="/subscription_management/:step"
        component={WithTracker(UserSubscriptionManagement)}
      />
      <PrivateRoute path="/user_lookup" component={WithTracker(UserLookup)} />
      <PrivateRoute path="/company_lookup" component={WithTracker(CompanyLookup)} />
      <PrivateRoute path="/offer/:id" component={WithTracker(DealNegotiation)} />
      <PrivateRoute path="/publish_demand" component={WithTracker(PublishDemand)} />
      <PrivateRoute path="/publish_demand_success" component={WithTracker(PublishDemandSuccess)} />
      <PrivateRoute path="/search_demand" component={WithTracker(SearchDemand)} />

      {/* ------------------ ERROR (MUST COME LAST) ------------------*/}
      <Route component={WithTracker(ErrorPage)} />
    </Switch>
  </Suspense>
)
