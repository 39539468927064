import { createStore, applyMiddleware, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { composeWithDevTools } from 'remote-redux-devtools'
import thunk from 'redux-thunk'
import reducer from './ducks/reducers/main_reducer'
import navReducer from './ducks/reducers/dealNegotioationReducer'

const composeEnhancers = composeWithDevTools({
  name: `Chunker`,
  hostname: 'localhost',
  port: 5671,
})

const objMiddleware =
  process.env.REACT_APP_ENV === 'dev'
    ? composeEnhancers(applyMiddleware(thunk))
    : applyMiddleware(thunk)

const rootReducer = combineReducers({
  app: reducer,
  form: formReducer,
  nav: navReducer,
})

export default createStore(rootReducer, objMiddleware)
