import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { isFunction } from 'lodash'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function Row(props) {
  const { row } = props
  const { planId } = row
  const [open, setOpen] = React.useState(row.collapsible.defaultOpen)
  const [listHistoryItems, setListHistoryItems] = React.useState([])
  const classes = useRowStyles()
  const fnAsyncData = row.collapsible.asyncData
  React.useEffect(() => {
    if (isFunction(fnAsyncData)) {
      fnAsyncData().then((listItems) => {
        const _listItems = row.collapsible.mapFn(listItems)
        setListHistoryItems(_listItems)
      })
    } else {
      setListHistoryItems(row.collapsible.data.map(row.collapsible.mapfn))
    }
  }, [planId]) // eslint-disable-line

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {row.items.map((item, i) => {
          if (i === 0) {
            return (
              <TableCell component="th" scope="row">
                {item}
              </TableCell>
            )
          }
          return <TableCell align="right">{item}</TableCell>
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.colSpan}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div" style={{ color: '#757575' }}>
                {props.collapseHeader}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {row.collapsible.tableHead.map((title) => (
                      <TableCell style={{ color: '#757575' }}>{title}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listHistoryItems.map((historyRow, i) => {
                    const styles =
                      i === 0 && props.boldFirstRow
                        ? {
                            color: '#000',
                            fontWeight: 'bold',
                          }
                        : {
                            color: '#757575',
                          }
                    return (
                      <TableRow key={`${historyRow[1]}`}>
                        {historyRow.map((item, k) => {
                          if (k === 0) {
                            return (
                              <TableCell component="th" scope="row" style={styles}>
                                {item}
                              </TableCell>
                            )
                          }
                          return <TableCell style={styles}>{item}</TableCell>
                        })}

                        {/* <TableCell align="right">
                          {Math.round(historyRow.amount * row.price * 100) / 100}
                        </TableCell> */}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
}

export default function CollapsibleTable({ tableHead, tableData, collapseHeader, boldFirstRow }) {
  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {tableHead.map((title, i) => (
              <TableCell align={i === 0 ? 'left' : 'right'}>{title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <Row
              key={row.key}
              row={row}
              colSpan={tableHead.length + 1}
              collapseHeader={collapseHeader}
              boldFirstRow={boldFirstRow}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

CollapsibleTable.defaultProps = {
  collapseHeader: 'History',
}
