import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import LinearProgress from '@material-ui/core/LinearProgress'
import Spinner from '../components/Spinner'
import { registerHeapEvent } from './RegisterHeapEvent'

const renderIcon = (props, strPosition) => {
  const Icon = props.icon

  if (!Icon) {
    return null
  }

  return (
    <Icon
      style={{
        position: 'relative',
        top: '6px',
        height: props.iconSize ? props.iconSize : '23px',
        width: props.iconSize ? props.iconSize : '20px',
        color: props.customColor ? props.customColor : '#FFFFFF',
        display: props[strPosition] ? 'inline-block' : 'none',
      }}
    />
  )
}

function handleClick(props, e) {
  const { text, meta, onClick, children } = props
  const buttonText = text || children
  registerHeapEvent('Button Click', 'button_name', meta ? `${meta}_${buttonText}` : buttonText)
  return onClick ? onClick(e) : null
}

const ChunkerButton = React.forwardRef((props, ref) => {
  const btnStyle = {
    padding: '0px 15px 12px 15px',
    color: '#fff',
    fontWeight: '600',
    fontSize: '14px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    lineHeight: 0,
    border: 'none',
    borderRadius: '4px',
    outline: 'none',
    ...props.style,
  }

  if (props.fullWidth && props.isLoading) {
    // button must be full width to show spinner or button would shrink to fit its children which now has no text
    return (
      <Button
        {...props}
        innerRef={ref}
        onClick={(e) => {
          handleClick(props, e)
        }}
      >
        <Spinner size="1rem" color="white" />
      </Button>
    )
  }

  if (!props.icon) {
    return (
      <Button
        {...props}
        innerRef={ref}
        style={{ textAlign: 'center', ...props.style }}
        onClick={(e) => {
          handleClick(props, e)
        }}
      >
        {props.children}
      </Button>
    )
  }

  return (
    <Button
      innerRef={ref}
      id={props.id}
      data-cyid={props['data-cyid']}
      color={props.color}
      variant={props.variant ? props.variant : 'contained'}
      onClick={(e) => {
        handleClick(props, e)
      }}
      style={{ ...btnStyle, ...props.style }}
      disabled={!!props.disabled}
      fullWidth={props.fullWidth}
    >
      {renderIcon(props, 'iconLeft')}
      <Typography variant="button" className="margin-left-5" style={{ color: props.customColor }}>
        {props.text || props.children}
      </Typography>
      {renderIcon(props, 'iconRight')}
    </Button>
  )
})

const ChunkerButtonTooltip = React.forwardRef((props, ref) => {
  if (props.disabledTooltipText && props.disabled) {
    return (
      <Tooltip title={<p style={{ fontSize: '12px' }}>{props.disabledTooltipText}</p>}>
        <div>
          <ChunkerButton {...props} ref={ref} />
        </div>
      </Tooltip>
    )
  }
  if (props.disabled && props.percentComplete) {
    return (
      <div>
        <ChunkerButton {...props} ref={ref} />
        <LinearProgress value={props.percentComplete} variant="determinate" />
      </div>
    )
  }
  return <ChunkerButton {...props} ref={ref} />
})

export default ChunkerButtonTooltip
