import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Manager, Target, Popper } from 'react-popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import Portal from '@material-ui/core/Portal'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMore from '@material-ui/icons/ExpandMore'

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  popperClose: {
    pointerEvents: 'none',
  },
  selectButton: {
    marginRight: '10px',
    padding: '3px 8px 8px 16px',
    color: '#fff',
    fontWeight: '600',
    fontSize: '14px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    lineHeight: 0,
    background: '#565656',
    border: 'none',
    borderRadius: '4px',
    outline: 'none',
    '&:hover': {
      backgroundColor: '#757575',
      cursor: 'pointer',
    },
  },
  editButton: {
    marginRight: '10px',
    padding: '6px 15px 8px 10px',
    color: '#fff',
    fontWeight: '600',
    fontSize: '14px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    lineHeight: 0,
    background: '#565656',
    border: 'none',
    borderRadius: '4px',
    outline: 'none',
    '&:hover': {
      backgroundColor: '#757575',
      cursor: 'pointer',
    },
  },
  buttonMenuItem: {
    padding: '8px 16px 3px',
    lineHeight: '15px',
    height: 'auto',
    '&:hover': {
      backgroundColor: '#F3F1EF',
      cursor: 'pointer',
    },
  },
  buttonMenuItemText: {
    fontSize: '14px',
    fontFamily: 'Saira-Condensed-Bold',
    textTransform: 'uppercase',
  },
  button: {
    fontFamily: 'Saira-Condensed-Bold',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    height: '100%',
    width: '100%',
    color: 'white',
    textDecoration: 'none',
    margin: ' 0 6px',
    '&:hover': {
      '& .dropdown-icon, &': {
        color: '#e0e0e0',
        cursor: 'pointer',
      },
    },
  },
  // Icon has large amounts of padding on each side.
  // This positions the icon with even spacing between text and icon.
  iconContainer: {
    position: 'relative',
    height: '12px',
    top: '-5px',
    width: '14px',
  },
  icon: {
    position: 'relative',
    height: '22px',
    width: '19px',
    color: '#FFFFFF',
  },
})

class MenuListComposition extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleToggle = () => {
    this.setState((prevState) => ({ open: !prevState.open }))
  }

  handleClose = (event) => {
    // sometimes this fires unexpectedly and errors out. not entirely sure why but I think this should work. maybe revisit this later.
    try {
      if (this.target2.contains(event.target)) {
        return
      }
    } catch (err) {
      // continue regardless of error
    }
    this.setState({ open: false })
  }

  renderMenuItem = (menuObj, i) => {
    const { classes } = this.props
    const { label, path } = menuObj
    const isActive = window.location.pathname === path

    return (
      <MenuItem
        onClick={() => this.handleMenuItemClick(path)}
        className={classes.buttonMenuItem}
        key={`expandableMenuButton_${i}`}
      >
        <Typography
          style={isActive ? { color: '#ed8b00' } : { color: '#9e9e9e' }}
          className={classes.buttonMenuItemText}
        >
          {label}
        </Typography>
      </MenuItem>
    )
  }

  handleMenuItemClick = (path) => {
    this.handleClose()
    this.props.history.push(path)
  }

  render() {
    const { classes, label } = this.props
    const { open } = this.state

    return (
      <div className={classes.root}>
        <Manager>
          <Target>
            <div
              ref={(node) => {
                this.target2 = node
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <p className={classes.button} onClick={this.handleToggle}>
                {label}
                <span className={classes.iconContainer}>
                  <ExpandMore className={`${classes.icon} dropdown-icon`} />
                </span>
              </p>
            </div>
          </Target>
          <Portal>
            <Popper
              placement="bottom"
              eventsEnabled={open}
              className={classNames({ [classes.popperClose]: !open })}
              style={{
                zIndex: 9999,
              }}
            >
              <ClickAwayListener onClickAway={this.handleClose}>
                <Collapse in={open} id="menu-list-collapse" style={{ transformOrigin: '0 0 0' }}>
                  <Paper
                    style={{
                      marginTop: '15px',
                      paddingBottom: '5px',
                      borderRadius: '4px',
                    }}
                  >
                    {this.props.subLinks.map(this.renderMenuItem)}
                  </Paper>
                </Collapse>
              </ClickAwayListener>
            </Popper>
          </Portal>
        </Manager>
      </div>
    )
  }
}

MenuListComposition.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MenuListComposition)
