import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

function Spinner(props) {
  return (
    <CircularProgress
      {...props}
      style={{
        color: props.color || '#ed8b00',
      }}
    />
  )
}

export default Spinner
