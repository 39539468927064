import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import Moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import ColapsableTable from '../ColapsableTable'
import ChunkerButton from '../../utilities/ChunkerButton'
import { getAllOffers, getAllOffersCount } from '../../services/searchService'
import { formatNumbersWithCommas } from '../../utilities/numberFormats'
import { updateCount } from '../../ducks/reducers/dealNegotioationReducer'
import attachListingValuesTOffers from '../../utilities/offerUtils/attachListingValuesToOffers'
import formatListingPrice, {
  getPriceOrListingSpaceAdornment,
} from '../../utilities/viewUtils/formatListingPrice'

const NUM_OFFERS_PER_PAGE = 3
const tableHead = ['Listing Title', 'Created On', 'Created By', 'Action']

export function offerRequiresAction({ listingOfferItems }) {
  const sortedItems = orderBy(listingOfferItems, ['id'], ['desc'])
  const latestOffer = sortedItems[0]
  return !latestOffer?.creator
}

function Table(props) {
  const [offers, setOffers] = useState([])
  const [activeStep, setActiveStep] = useState(1)
  const [numOffers, setNumOffers] = useState(0)

  const getTableData = () =>
    offers.map((offer) => {
      const currentUserActionIsRequired = offerRequiresAction(offer)
      const offerWithAttachments = attachListingValuesTOffers(offer)
      const listingOfferItems =
        offerWithAttachments.listingOfferItems?.slice(0).sort((a, b) => b.id - a.id) ?? []

      return {
        key: offer.id,
        items: [
          offerWithAttachments.listingTitle,
          Moment(offer?.createdDate).format('LLL'),
          offer?.listingOfferItems
            ? `${listingOfferItems?.[listingOfferItems.length - 1]?.createdByFirstName} from ${
                listingOfferItems?.[listingOfferItems.length - 1]?.createdByCompanyLegalName
              }`
            : null,
          <ChunkerButton
            onClick={() => props.history.push(`/offer/${offer?.id}`)}
            size="small"
            variant="contained"
            color={currentUserActionIsRequired ? 'primary' : 'secondary'}
          >
            {currentUserActionIsRequired ? 'Respond to new offer' : 'Manage Pending Offer'}
          </ChunkerButton>,
        ],
        collapsible: {
          tableHead: [
            'Offer Created By',
            'Created On',
            'Start Date',
            'End Date',
            'Space Requested',
            'Price',
          ],
          planId: offer?.id,
          data: listingOfferItems,
          mapfn: (item) => [
            `${item?.createdByFirstName} from ${item?.createdByCompanyLegalName}`,
            Moment(item?.createdDate).format('LLL'),
            Moment(item?.startDate).format('LL'),
            Moment(item?.endDate).format('LL'),
            `${formatNumbersWithCommas(item?.requestedSize)} ${getPriceOrListingSpaceAdornment(
              item?.listingUsePalletPricing,
              item?.listingPropertyRawProperty,
              item?.listingPropertyMeasurementUnitType?.id,
              true,
            )}`,
            formatListingPrice(
              item?.pricePerUnit,
              item?.listingUsePalletPricing,
              item?.listingPropertyRawProperty,
              item?.listingPropertyMeasurementUnitType?.id,
            ),
          ],
        },
      }
    })

  // FIX NAV ALERT LATER
  // useEffect(() => {
  //   getAllOffers().then((_offers) => {
  //     let numOffersRequiringAction = _offers.reduce((total, currentOffer) => {
  //       return offerRequiresAction(currentOffer) ? total + 1 : total;
  //     }, 0);
  //     //for nav alert
  //     props.updateCount(numOffersRequiringAction);
  //     setOffers(_offers);
  //   }); // eslint-disable-next-line
  //   getAllOffersCount().then(({ totalCount }) => {
  //     setNumOffers(totalCount);
  //   });
  // }, []);

  useEffect(() => {
    getAllOffersCount().then(({ totalCount }) => {
      setNumOffers(totalCount)
    })
  }, [])

  useEffect(() => {
    getAllOffers(activeStep - 1, NUM_OFFERS_PER_PAGE).then((_offers) => setOffers(_offers))
  }, [activeStep])

  if (!offers.length) {
    return null
  }
  return (
    <Paper style={{ padding: '2px 24px 24px', marginBottom: '24px' }}>
      <p
        style={{
          color: '#424242',
          fontSize: '20px',
          fontFamily: 'TG-Bold-Condensed-20',
          fontWeight: 400,
          margin: '20px 0',
        }}
      >
        Active Offers
      </p>
      <ColapsableTable
        tableHead={tableHead}
        tableData={getTableData()}
        collapseHeader="Offer History"
        boldFirstRow
      />
      {numOffers > NUM_OFFERS_PER_PAGE && (
        <Grid container justify="center">
          <Pagination
            activeStep={activeStep}
            shape="rounded"
            count={Math.ceil(numOffers / NUM_OFFERS_PER_PAGE)}
            page={activeStep}
            onChange={(e, page) => setActiveStep(page)}
          />
        </Grid>
      )}
    </Paper>
  )
}

function mapStateToProps() {
  return {}
}
export default connect(mapStateToProps, {
  updateCount,
})(Table)
