import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'

export function getEncryptedConfig(payloadObj, absoluteValue) {
  const hash = hmacSHA256(
    absoluteValue ? payloadObj : JSON.stringify(payloadObj),
    process.env.REACT_APP_SIGNATURE_SECRET,
  )
  const hashInBase64 = Base64.stringify(hash)
  return {
    headers: {
      'Content-Type': 'application/json',
      'x-chk-signature': hashInBase64,
    },
  }
}

export function extractResult(objResponse) {
  return objResponse.data
}
