import React from 'react'
import { get, isEmpty } from 'lodash'
import HoistNonReactStatic from 'hoist-non-react-statics'
import Snackbar from '../../components/Snackbar'
import logger from '../../core/logger'
import ChunkerButton from '../../utilities/ChunkerButton'

/// /////////////////////////////////////////////////////////

function LoggerHOC(WrappedComponent, isOnContainer) {
  function getMessage(strType, objPayload) {
    const errorStr =
      get(objPayload.data, ['response', 'headers', 'x-chunker-error']) ||
      get(objPayload.data, ['response', 'data', 'detail'])
    if (objPayload.message) {
      return objPayload.message
    }

    if (!isEmpty(logger.messages[objPayload.key])) {
      return logger.messages[objPayload.key]
    }

    const strServerErrorKey = get(objPayload.data, ['response', 'headers', 'x-chunker-error-key'])
    if (!isEmpty(logger.messages[strServerErrorKey])) {
      return logger.messages[strServerErrorKey]
    }

    if (errorStr) {
      localStorage.setItem('lastError', errorStr)
    }

    const errorMessage = (
      <div data-cyid="logger-alert-error">
        <p>Oops! We apologize that something went wrong.</p>
        <p>
          Our development team has been notified. Feel free to chat with us below or click the
          button to fill out a form.
        </p>
        <ChunkerButton
          variant="contained"
          color="secondary"
          onClick={() => {
            window.open(`/support`, 'blank')
          }}
        >
          CONTACT CHUNKER
        </ChunkerButton>
      </div>
    )

    if (errorMessage && strType === 'ERROR') {
      return errorMessage
    }

    return logger.messages[`DEFAULT_${strType}`]
  }

  const _Logger = class Logger extends React.Component {
    constructor(objProps) {
      super(objProps)

      this.state = {
        notification: null,
      }
      this.logger = {
        error: this.error,
        success: this.success,
        warn: this.warn,
      }
    }

    handleNotificationClose = () => {
      if (localStorage.getItem('lastError')) {
        localStorage.removeItem('lastError')
      }
      this.setState({ notification: null })
    }

    showNotification = (objNotification, keepError) => {
      if (objNotification.message) {
        this.setState({ notification: objNotification })

        if (!keepError) {
          setTimeout(() => {
            this.setState({ notification: null })
          }, 10000)
        }
      }
    }

    error = (objPayload) => {
      const objNotification = {
        color: 'danger',
        message: getMessage('ERROR', objPayload),
      }

      this.showNotification(objNotification, false)
      console.error(objPayload)
    }

    success = (objPayload) => {
      const objNotification = {
        color: 'success',
        message: getMessage('SUCCESS', objPayload),
      }

      this.showNotification(objNotification, false)
      console.info(objPayload)
    }

    warn = (objPayload) => {
      const objNotification = {
        color: 'warning',
        message: getMessage('WARNING', objPayload),
      }

      this.showNotification(objNotification, false)
      console.warn(objPayload)
    }

    // eslint-disable-next-line class-methods-use-this
    confirm = () => {
      // TODO: Implement confirm action
    }

    // eslint-disable-next-line class-methods-use-this
    alert = () => {
      // TODO: Implement alert action
    }

    renderSnackbar = () => {
      if (this.state.notification) {
        return (
          <Snackbar
            place="tr"
            open
            closeNotification={this.handleNotificationClose}
            onClose={this.handleNotificationClose}
            close
            isOnContainer={isOnContainer}
            {...this.state.notification}
            data-cyid="snackbar"
          />
        )
      }
      return null
    }

    render() {
      return (
        <>
          {this.renderSnackbar()}
          <WrappedComponent {...this.props} logger={this.logger} />
        </>
      )
    }
  }

  HoistNonReactStatic(_Logger, WrappedComponent)

  return _Logger
}

/// /////////////////////////////////////////////////////////

export default LoggerHOC
