import chunkerRequest, { standardGet } from './chunkerRequest'

export function getAllOffers(page = 0, size = 3) {
  const strUrl = `/api/_search/listing-offers?query=NOT archived:true&size=${size}&page=${page}&sort=id%2Cdesc`
  return standardGet(strUrl)
}

export function getNewAndInProgressListings() {
  const newState = 1
  const inProgressState = 2
  const strUrl = `/api/_search/listings?query=listingRecordState.id:${newState} OR listingRecordState.id:${inProgressState}`
  return chunkerRequest.get(strUrl).then()
}

export function getPublishedListings() {
  const publishedState = 3
  const strUrl = `/api/_search/listings?query=listingRecordState.id:${publishedState}`
  return chunkerRequest.get(strUrl).then()
}

export function getListingsPendingApproval() {
  const pendingState = 7
  const strUrl = `/api/_search/listings?query=listingRecordState.id:${pendingState}`
  return chunkerRequest.get(strUrl).then()
}

export function getAllPublishedListings() {
  const strUrl = '/api/_search/listings?query=(listingRecordState.id:3)'
  return standardGet(strUrl)
}

export function getAllLicenses() {
  const strUrl = '/api/_search/licenses-dto?query=*'
  return standardGet(strUrl)
}

export function searchBrokerage(searchStr) {
  const strUrl = `/api/_search/brokerages?query=legalName%3A${searchStr}*&size=5`
  return standardGet(strUrl)
}

export function searchUserByEmail(emailStr) {
  const strUrl = `/api/admin/_search/users?query=email:${emailStr}*&size=5`

  return standardGet(strUrl)
}

export function searchUserByName(nameStr) {
  const strUrl = `/api/admin/_search/users?query=${nameStr}*&size=5`
  return standardGet(strUrl)
}

export function getSubscriptionsFromUserId(userId) {
  const strUrl = `/api/_search/listing-subscriptions?query=userId:${userId}`
  return standardGet(strUrl)
}

export function getUserAllProperties() {
  const strUrl = '/api/_search/properties?query=*'
  return standardGet(strUrl)
}

export function searchProperties(searchStr) {
  const strUrl = `/api/_search/properties?query=name%3A${searchStr}*&size=5`
  return standardGet(strUrl)
}

export function getAllProperties() {
  const strUrl = `/api/_search/properties?query=*`
  return standardGet(strUrl)
}

export function getTotalNumberOfProperties() {
  const strUrl = '/api/_search/properties?query=*&page=0&size=1'
  return chunkerRequest.get(strUrl).then((res) => res.headers['x-total-count'])
}

export function getTotalNumberOfActiveLicenses() {
  const strUrl =
    '/api/_search/licenses?page=0&query=licenseRecordState.id%3D1%20OR%20licenseRecordState.id%3D2%20OR%20licenseRecordState.id%3D3%20OR%20licenseRecordState.id%3D4%20OR%20licenseRecordState.id%3D10%20OR%20licenseRecordState.id%3D11&size=1'
  return chunkerRequest.get(strUrl).then((res) => res.headers['x-total-count'])
}

export function getListOfActiveUserLicensesByListingId(listingId) {
  const strUrl = `/api/_search/licenses?query=listingId%3A${listingId}%20AND%20(%20licenseRecordState.id%3A1%20OR%20licenseRecordState.id%3A2%20OR%20licenseRecordState.id%3A3%20OR%20licenseRecordState.id%3A4%20OR%20licenseRecordState.id%3A10%20OR%20licenseRecordState.id%3A11%20)`
  return standardGet(strUrl)
}

export function getAllUsersListings() {
  const strUrl = '/api/_search/listings?query=*&page=0&size=1'
  return standardGet(strUrl)
}

export function getTotalNumberOfListings() {
  const strUrl = '/api/_search/listings?query=*&page=0&size=1'
  return chunkerRequest.get(strUrl).then((res) => res.headers['x-total-count'])
}

export function getUserInfoObj(userId) {
  const strUrl = `/api/_search/user-infos?query=user.id%3A${userId}&queryParams=*`
  return standardGet(strUrl)
}

export function getTagsByCategory(categoryId) {
  const strUrl = `/api/_search/license-template-tags?query=licenseTemplateTagCategory.id%3A${categoryId}`
  return standardGet(strUrl)
}

export function searchCompany(searchStr) {
  const strUrl = `/api/_search/companies?query=legalName%3A${searchStr}*&size=5`
  return standardGet(strUrl)
}

export function searchLicenseTemplates(page, size) {
  const strUrl = `/api/_search/license-templates?page=${page}&query=*&size=${size}&sort=created%2Cdesc`
  return chunkerRequest.get(strUrl).then((objResponse) => ({
    templates: objResponse.data,
    totalCount: objResponse.headers['x-total-count'],
  }))
}

export function countrySearch(countryStr) {
  const strUrl = `/api/_search/country-types?query=helpText%3A${countryStr}*&size=5`
  return standardGet(strUrl)
}

export function getRandomLicenseForTemplatePreview() {
  const strUrl = '/api/_search/licenses?page=0&query=*&size=1'
  return standardGet(strUrl)
}

export function getUserInfo(id) {
  const strUrl = `/api/_search/user-infos?page=0&query=user.id%3A${id}&size=1`
  return standardGet(strUrl)
}

export function searchUserInfo(searchTerm) {
  const strUrl = `/api/_search/user-infos?page=1&query=${searchTerm}&size=4`
  return standardGet(strUrl)
}

export function getAllOffersCount() {
  const strUrl = '/api/_search/listing-offers?query=NOT archived:true'
  return chunkerRequest.get(strUrl).then((objResponse) => ({
    totalCount: objResponse.headers['x-total-count'],
  }))
}

// TODO JVD: determine if location param is still used here
// eslint-disable-next-line no-unused-vars
export function searchDemand({ location, locationForLog, page, radiusMiles, size, lat, lon }) {
  const strUrl = `/api/_search/requirements/location?lat=${lat}&lon=${lon}&locationForLog=${locationForLog}&page=${page}&radiusMiles=${radiusMiles}&size=${size}`
  return chunkerRequest.post(strUrl).then((objResponse) => {
    console.log(objResponse)
    return {
      data: objResponse.data,
      count: objResponse.headers['x-total-count'],
    }
  })
}
