import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

/**
 * @description AppCheckbox
 * - Renders a checkbox with an optional description.
 *
 * @param {{
 *   onChange: (e: ChangeEventHandler) => void,
 *   checked: boolean,
 *   name: string,
 *   label: string,
 *   description?: string,
 *   checkboxProps?: object,
 *   formControlProps?: object,
 * }}
 *
 * @returns {JSX.Element}
 */

export default function AppCheckbox({
  onChange,
  checked,
  name,
  description,
  label,
  color,
  formControlProps = {},
  checkboxProps = {},
}) {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            color={color || 'primary'}
            checked={checked}
            name={name}
            onChange={onChange}
            {...checkboxProps}
          />
        }
        label={
          <Typography style={{ fontFamily: 'forzaBook', fontWeight: 'bold' }}>{label}</Typography>
        }
        {...formControlProps}
      />

      {description && (
        <Grid item xs={12}>
          <Typography>{description}</Typography>
        </Grid>
      )}
    </>
  )
}

AppCheckbox.defaultProps = {
  description: null,
}

AppCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
