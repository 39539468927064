import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: {
    textDecoration: 'underline',
    fontWeight: 600,
    cursor: 'pointer',
  },
}))

/**
 * @description ButtonLikeLink
 * - Renders a button like link that provides cursor hover and underline.
 *
 * @param {{
 *  children: Renders within the span element.
 *  onClick: Invokes when the span is clicked.
 * }}
 *
 * @returns {JSX.Element}
 */
export default function ButtonLikeLink({ children, onClick }) {
  const classes = useStyles()

  return (
    <span className={classes.root} onClick={onClick}>
      {children}
    </span>
  )
}
