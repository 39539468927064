/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

// TODO: JVD: Evaluate lifecycle methods to remove deprecated ones
import React, { Component } from 'react'
// import ReactGA from 'react-ga';

export default function withTracker(WrappedComponent = {}) {
  const trackPage = () => {
    /*    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
    */
  }

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname
      trackPage(page)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return process.env.REACT_APP_ENV === 'prod' ? HOC : WrappedComponent
}
