const initialState = {
  displayedListings: null,
  userStatus: false,
  createListing: '',
  createListingStreet: '',
  createListingState: '',
  createListingCity: '',
  createListingZip: '',
  listingObj: null,
  companyData: [],
  saving: false,
  percentComplete: 0,
  rememberMe: null,
  listingsComplete: [],
  latLng: {},
  propertyObj: null,
  searchPageQuery: '',
  userInfoObject: {},
  queryObj: {},
}

const CREATE_LISTING = 'CREATE_LISTING'
const GET_LISTINGS = 'GET_LISTINGS'
const CHECK_USER_STATUS = 'CHECK_USER_STATUS'
const UPDATE_LISTING_STREET = 'UPDATE_LISTING_STREET'
const UPDATE_LISTING_STATE = 'UPDATE_LISTING_STATE'
const UPDATE_LISTING_CITY = 'UPDATE_LISTING_CITY'
const UPDATE_LISTING_ZIP = 'UPDATE_LISTING_ZIP'
const UPDATE_LISTING_COUNTRY = 'UPDATE_LISTING_COUNTRY'
const UPDATE_LISTING_OBJ = 'UPDATE_LISTING_OBJ'
const SET_COMPANY_DATA = 'SET_COMPANY_DATA'
const GET_SAVING_STATUS = 'GET_SAVING_STATUS'
const UPDATE_LISTING_OBJ_FROM_USER_INPUT = 'UPDATE_LISTING_OBJ_FROM_USER_INPUT'
const GET_PERCENT_COMPLETE = 'GET_PERCENT_COMPLETE'
const LIST_FILTERS = 'LIST_FILTERS'
const UPDATE_REMEMBER_ME = 'UPDATE_REMEMBER_ME'
const ADD_LISTINGS_COMPLETE = 'ADD_LISTINGS_COMPLETE'
const UPDATE_SAVING_STATUS = 'UPDATE_SAVING_STATUS'
const SAVE_SUCCESS = 'SAVE_SUCCESS'
const UPDATE_LISTING_LATLNG = 'UPDATE_LISTING_LATLNG'
const UPDATE_PROPERTY_OBJ = 'UPDATE_PROPERTY_OBJ'
const UPDATE_SEARCH_PAGE_QUERY = 'UPDATE_SEARCH_PAGE_QUERY'
const UPDATE_USERINFO_OBJ = 'UPDATE_USERINFO_OBJ'
const UPDATE_QUERY_OBJ = 'UPDATE_QUERY_OBJ'

export function updateQueryObj(queryObj) {
  return {
    type: UPDATE_QUERY_OBJ,
    payload: queryObj,
  }
}

export function updateUserInfoObj(obj) {
  return {
    type: UPDATE_USERINFO_OBJ,
    payload: obj,
  }
}

export function updateSearchPageQuery(query) {
  return {
    type: UPDATE_SEARCH_PAGE_QUERY,
    payload: query,
  }
}

export function updatePropertyObj(obj) {
  return {
    type: UPDATE_PROPERTY_OBJ,
    payload: obj,
  }
}

export function addListingsComplete(arr) {
  return {
    type: ADD_LISTINGS_COMPLETE,
    payload: arr,
  }
}

export function UpdateRememberMe(value) {
  return {
    type: UPDATE_REMEMBER_ME,
    payload: value,
  }
}

export function updateListFilters(filters) {
  return {
    type: LIST_FILTERS,
    payload: filters,
  }
}

export function updateSavingStatus(status) {
  return {
    type: GET_SAVING_STATUS,
    payload: status,
  }
}

export function setCompanyData(obj) {
  return {
    type: SET_COMPANY_DATA,
    payload: obj,
  }
}

export function updateObj(obj) {
  return {
    type: UPDATE_LISTING_OBJ,
    payload: obj,
  }
}

export function createListing(listing) {
  return {
    type: CREATE_LISTING,
    payload: listing,
  }
}

export function getListings(listings) {
  return {
    type: GET_LISTINGS,
    payload: listings,
  }
}

export function getUserStatus() {
  const status = localStorage.getItem('jwtToken') ?? sessionStorage.getItem('jwtToken')
  return {
    type: CHECK_USER_STATUS,
    payload: !!status,
  }
}

export function updateListingStreet(street) {
  return {
    type: UPDATE_LISTING_STREET,
    payload: street,
  }
}

export function updateListingState(state) {
  return {
    type: UPDATE_LISTING_STATE,
    payload: state,
  }
}

export function updateListingCity(city) {
  return {
    type: UPDATE_LISTING_CITY,
    payload: city,
  }
}

export function updateListingZip(zip) {
  return {
    type: UPDATE_LISTING_ZIP,
    payload: zip,
  }
}

export function updateListingCountry(country) {
  return {
    type: UPDATE_LISTING_COUNTRY,
    payload: country,
  }
}

export function updateListingLatLng(latLng) {
  return {
    type: UPDATE_LISTING_LATLNG,
    payload: latLng,
  }
}
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
  //    var listings = state.createListing
  switch (action.type) {
    case GET_LISTINGS:
      return { ...state, displayedListings: action.payload }
    case CHECK_USER_STATUS:
      return { ...state, userStatus: action.payload }
    case CREATE_LISTING:
      return { ...state, createListing: action.payload }
    case UPDATE_LISTING_STREET:
      return { ...state, createListingStreet: action.payload }
    case UPDATE_LISTING_STATE:
      return { ...state, createListingState: action.payload }
    case UPDATE_LISTING_CITY:
      return { ...state, createListingCity: action.payload }
    case UPDATE_LISTING_ZIP:
      return { ...state, createListingZip: action.payload }
    case UPDATE_LISTING_COUNTRY:
      return { ...state, createListingCountry: action.payload }
    case UPDATE_LISTING_LATLNG:
      return { ...state, latLng: action.payload }
    case UPDATE_LISTING_OBJ_FROM_USER_INPUT:
      return { ...state, listingObj: action.payload, saving: false }
    case UPDATE_LISTING_OBJ:
      return { ...state, listingObj: action.payload }
    case SET_COMPANY_DATA:
      return { ...state, companyData: action.payload }
    case GET_SAVING_STATUS:
      return { ...state, saving: action.payload }
    case GET_PERCENT_COMPLETE:
      return { ...state, percentComplete: action.payload }
    case LIST_FILTERS:
      return { ...state, listFilters: action.payload }
    case UPDATE_REMEMBER_ME:
      return { ...state, rememberMe: action.payload }
    case ADD_LISTINGS_COMPLETE:
      return { ...state, listingsComplete: action.payload }
    case UPDATE_SAVING_STATUS:
      return { ...state, saving: action.payload }
    case SAVE_SUCCESS:
      return { ...state, saving: false }
    case UPDATE_PROPERTY_OBJ:
      return { ...state, propertyObj: action.payload }
    case UPDATE_SEARCH_PAGE_QUERY:
      return { ...state, searchPageQuery: action.payload }
    case UPDATE_USERINFO_OBJ:
      return { ...state, userInfoObject: action.payload }
    case UPDATE_QUERY_OBJ:
      return { ...state, queryObj: action.payload }
    default:
      return state
  }
}
