import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { get } from 'lodash'

// REDUX
import { connect } from 'react-redux'

// MATERIAL UI
import Grid from '@material-ui/core/Grid'
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Hidden from '@material-ui/core/Hidden'
import { getUserStatus, updateUserInfoObj } from '../../ducks/reducers/main_reducer'
import ChunkerButton from '../../utilities/ChunkerButton'
import { getCurrentUser, updateUserInfos } from '../../core/api'

// COMPONENTS
import Stepper from '../../components/BasicMobileStepper'

// IMAGES
import SearchForPropertyImage from '../../images/tour/SearchForAProperty.png'
import ListAPropertyImage from '../../images/tour/ListAProperty.png'
import PublishYourListingVisual from '../../images/tour/PublishYourListingVisual.png'
import CreateListingVisual from '../../images/tour/CreateListingVisual.png'
import CreateAPropertyVisual from '../../images/tour/CreateAPropertyVisual.png'
import LicensePropertyVisual from '../../images/tour/LicensePropertyVisual.png'
import SearchListingsVisual from '../../images/tour/SearchListingsVisual.png'
import CreateAnAccountVisual from '../../images/tour/CreateAnAccountVisual.png'
import {
  getAllLicenses,
  getAllUsersListings,
  getTotalNumberOfActiveLicenses,
  getUserAllProperties,
} from '../../services/searchService'
import Spinner from '../../components/Spinner'
import { Fade } from '@material-ui/core'
import { URLS } from '../../core/urls'
import { UserContext } from '../../context/UserContext'

const styles = () => ({
  root: {
    flexGrow: 1,
    padding: '60px',
  },
  showOptionsItemsContainer: {
    '&:hover': {
      backgroundColor: '#fafafa',
      cursor: 'pointer',
    },
  },
  largeImageIcon: {
    maxWidth: '250px',
    margin: '80px 0 40px 0',
  },
  LargeIconOnSteps: {
    maxWidth: '240px',
    maxHeight: '240px',
    height: 'auto',
    width: 'auto',
  },
  orangeText: {
    color: '#ed8b00',
    padding: '0 8px 20px',
    fontSize: '34px',
    lineHeight: '32px',
  },
  activeText: {
    color: '#ed8b00',
  },
  inactiveText: {
    color: '#d7d2cb',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
      userSelect: 'none' /* Non-prefixed version, currently supported by Chrome and Opera */,
    },
  },
})

class userTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showOptions: true,
      showSearchOption: false,
      activeStep: 1,
      // just for local display, obj on redux or props is usually used. I had to introduce this for when obj is not on redux.
      userInfoObject: {},
      hideTour: false,
      isLoading: true,
    }
  }

  componentDidMount() {
    const { activeStep } = this.props
    if (activeStep) {
      this.setState({
        showOptions: false,
        activeStep,
      })
    } else {
      this.getCurrentUsersPredictedStep(true)
    }
    getCurrentUser().then((userInfoObject) => {
      this.setState({
        hideTour: userInfoObject.hideTour,
        userInfoObject,
      })
    })
  }
  getCurrentUsersPredictedStep = (init) => {
    try {
      this.setState({ isLoading: true })

      /**
       * Check if user is logged in
       * Check if user has properties
       * check if they have active licenses.
       *  */
      getTotalNumberOfActiveLicenses().then((numOfLicenses) => {
        if (Number(numOfLicenses)) {
          this.setState({ userHasActiveLicenses: true })
        }
        if (init) {
        } else {
          // IF USER IS SIGNED IN
          if (this.context.status.isUserLoggedIn) {
            this.setState(
              {
                activeStep: 1,
              },
              () => {
                // CHECK IF USER HAS PROPERTIES
                if (!this.state.showSearchOption) {
                  getUserAllProperties().then((properties) => {
                    if (properties.length) {
                      this.setState(
                        {
                          activeStep: 2,
                        },
                        () => {
                          // CHECK IF USER HAS LISTINGS
                          getAllUsersListings().then((listings) => {
                            if (listings.length) {
                              this.setState({
                                activeStep: 3,
                              })
                            }
                          })
                        },
                      )
                    }
                  })
                } else {
                  // IF THEY ARE LOOKING TO RENT SPACE, CHECK IF USER HAS ANY LICENSES
                  getAllLicenses().then((licenses) => {
                    if (licenses.length) {
                      this.setState({
                        activeStep: 2,
                      })
                    }
                  })
                }
              },
            )
          }
        }

        this.setState({ isLoading: false })
      })
    } catch (err) {
      console.error(err)
      this.setState({ isLoading: false })
    }
  }

  handleButtonClick = (showSearchOption) => {
    this.setState({ showOptions: false, showSearchOption })
    this.getCurrentUsersPredictedStep(false)
  }

  getStepOptions = (getStepOptions) => {
    if (getStepOptions) {
      return [
        {
          heading: '1. CREATE AN ACCOUNT',
          subheading: "Let's begin by creating your account. From there you can list properties.",
          stepCount: 0,
          redirUrl: '/register',
          img: CreateAnAccountVisual,
        },
        {
          heading: '2. SEARCH LISTINGS',
          subheading:
            'Search listings now. Once you find a great location, you can begin the licensing process.',
          stepCount: 1,
          redirUrl: '/search',
          disabled: false,
          tooltipText: null,
          img: SearchListingsVisual,
        },
        {
          heading: '3. LICENSE A PROPERTY',
          subheading: 'Sign and complete the license agreement right within the Chunker system.',
          stepCount: 2,
          redirUrl: '/search',
          disabled: false,
          tooltipText: null,
          img: LicensePropertyVisual,
        },
      ]
    }
    return [
      {
        heading: '1. CREATE AN ACCOUNT',
        subheading: "Let's begin by creating your account. From there you can list properties.",
        stepCount: 0,
        redirUrl: '/register',
        img: CreateAnAccountVisual,
      },
      {
        heading: '2. CREATE A PROPERTY',
        subheading: 'A property profile must be created first.',
        stepCount: 1,
        redirUrl: URLS.propertiesPath,
        img: CreateAPropertyVisual,
      },
      {
        heading: '3. CREATE A LISTING',
        subheading: 'Create a new listing from the property profile.',
        stepCount: 2,
        redirUrl: URLS.warehousesAllPath,
        tooltipText: null,
        img: CreateListingVisual,
      },
      {
        heading: '4. PUBLISH YOUR LISTING',
        subheading: 'Users can search and review your listing.',
        stepCount: 3,
        redirUrl: URLS.warehousesAllPath,
        disabled: false,
        tooltipText:
          "Open the listing you would like to publish, scroll to the bottom, and click 'publish now'.",
        img: PublishYourListingVisual,
      },
    ]
  }
  handleNextClick = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }))
  }

  handleBackClick = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }))
  }

  changeStep = (clickedStep) => {
    this.setState({
      activeStep: clickedStep,
    })
  }

  redirectPage = async (redirUrl) => {
    const { handleRequestClose, history } = this.props
    if (handleRequestClose) {
      await handleRequestClose()
    }
    history.push(redirUrl)
  }

  handleCheck = (event) => {
    const userObj = this.state.userInfoObject
    const size = Object.keys(userObj).length
    const { checked } = event.target
    this.setState({
      hideTour: checked,
    })
    if (size > 0) {
      if (userObj.hideTour === null) {
        this.props.updateUserInfoObj({ ...userObj, hideTour: false })
        updateUserInfos({ ...userObj, hideTour: false })
      } else {
        this.props.updateUserInfoObj({ ...userObj, hideTour: checked })
        updateUserInfos({ ...userObj, hideTour: checked })
      }
    }
    getCurrentUser().then((userInfoObj) => {
      this.props.updateUserInfoObj({ ...userInfoObj, hideTour: checked })
      updateUserInfos({ ...userInfoObj, hideTour: checked })
    })
  }

  renderCheckbox = () => (
    <FormControlLabel
      control={<Checkbox checked={this.state.hideTour} onChange={this.handleCheck} />}
      label="Do not show this message again"
    />
  )

  handleTakeMeBack = () => {
    this.setState({
      showOptions: true,
      showSearchOption: false,
      activeStep: 1,
    })
  }

  render() {
    const { classes } = this.props
    const { activeStep, showOptions, showSearchOption, isLoading } = this.state
    const searchOptionClicked = true
    const listAPropertyOptionClicked = false
    const steps = this.getStepOptions(showSearchOption)
    if (showOptions) {
      return (
        <div className={classes.root} data-cyid="tour-wizard-fade-options">
          {!isLoading ? (
            <Fade in>
              <Grid container spacing={6}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: '10px' }}
                >
                  <Typography variant="h4"> What would you like to do?</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={this.state.userHasActiveLicenses ? 4 : 6}
                  className={classes.showOptionsItemsContainer}
                  onClick={() => {
                    this.handleButtonClick(searchOptionClicked)
                  }}
                >
                  <Grid container justifyContent="center" alignItems="center" spacing={0}>
                    <Grid item>
                      <img
                        className={classes.largeImageIcon}
                        src={SearchForPropertyImage}
                        alt="search for a property"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Typography variant="h6" className={classes.orangeText} align="center">
                          Search For Properties
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={this.state.userHasActiveLicenses ? 4 : 6}
                  className={classes.showOptionsItemsContainer}
                  onClick={() => {
                    this.handleButtonClick(listAPropertyOptionClicked)
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    style={{ height: '100%' }}
                  >
                    <Grid item>
                      <img
                        className={classes.largeImageIcon}
                        src={ListAPropertyImage}
                        alt="list a property"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Typography variant="h3" className={classes.orangeText} align="center">
                          List a Property
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.showOptionsItemsContainer}
                  onClick={() => this.redirectPage(URLS.licensesAllPath)}
                  style={{
                    display: this.state.userHasActiveLicenses ? null : 'none',
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    style={{ height: '100%' }}
                  >
                    <Grid item>
                      <img
                        className={classes.largeImageIcon}
                        src={LicensePropertyVisual}
                        alt="Active Licenses"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Typography variant="h6" className={classes.orangeText} align="center">
                          Active Licenses
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {!this.props.hideCheckbox && (
                  <Grid container justifyContent="flex-end">
                    <Grid item>{this.renderCheckbox()}</Grid>
                  </Grid>
                )}
              </Grid>
            </Fade>
          ) : (
            <Spinner />
          )}
        </div>
      )
    }

    if (!showOptions) {
      return (
        <div className={classes.root}>
          <Grid container spacing={6}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography variant="h3">
                  {showSearchOption ? 'Search for Properties' : 'List a Property'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%', minHeight: '35vh' }}
              >
                <Grid item>
                  {steps.map((step, i) => {
                    if (activeStep === step.stepCount) {
                      return (
                        <img
                          className={classes.LargeIconOnSteps}
                          src={step.img}
                          key={i}
                          alt="search or list property icon"
                        />
                      )
                    }
                    return null
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* DESKTOP */}
              <Hidden smDown>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginBottom: '0px',
                    paddingRight: '20px',
                    height: '100%',
                  }}
                >
                  {steps.map((step, i) => (
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      style={i < steps.length ? { marginBottom: '10px' } : { marginBottom: '0px' }}
                      key={i}
                    >
                      <Grid
                        item
                        xs={9}
                        className={classes.clickable}
                        onClick={() => {
                          this.changeStep(step.stepCount)
                        }}
                      >
                        <Typography
                          variant="h2"
                          className={
                            activeStep === step.stepCount
                              ? classes.activeText
                              : classes.inactiveText
                          }
                        >
                          {step.heading}
                        </Typography>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            className={
                              activeStep === step.stepCount
                                ? classes.activeText
                                : classes.inactiveText
                            }
                          >
                            {step.subheading}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {step.tooltipText ? (
                          <Tooltip title={step.tooltipText}>
                            <div>
                              <ChunkerButton
                                variant="contained"
                                color="primary"
                                size="small"
                                style={
                                  activeStep !== step.stepCount || step.disabled
                                    ? null
                                    : { backgroundColor: '#f9cc1f' }
                                }
                                onClick={() => this.redirectPage(step.redirUrl)}
                                disabled={activeStep !== step.stepCount || step.disabled}
                              >
                                Show Me
                              </ChunkerButton>
                            </div>
                          </Tooltip>
                        ) : (
                          <ChunkerButton
                            variant="contained"
                            color="primary"
                            size="small"
                            style={
                              activeStep !== step.stepCount || step.disabled
                                ? null
                                : { backgroundColor: '#f9cc1f' }
                            }
                            onClick={() => this.redirectPage(step.redirUrl)}
                            disabled={activeStep !== step.stepCount || step.disabled}
                          >
                            Show Me
                          </ChunkerButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Hidden>
              {/* MOBILE */}
              <Hidden mdUp>
                <Grid container justifyContent="center" alignItems="center">
                  {steps.map((step, i) => {
                    const { activeStep } = this.state
                    if (activeStep === step.stepCount) {
                      return (
                        <Grid container justifyContent="space-between" alignItems="center" key={i}>
                          <Grid
                            item
                            xs={12}
                            className={classes.clickable}
                            onClick={() => {
                              this.changeStep(step.stepCount)
                            }}
                          >
                            <Typography
                              variant="headline"
                              style={{ fontSize: '20px' }}
                              className={
                                activeStep === step.stepCount
                                  ? classes.activeText
                                  : classes.inactiveText
                              }
                            >
                              {step.heading}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={
                                activeStep === step.stepCount
                                  ? classes.activeText
                                  : classes.inactiveText
                              }
                            >
                              {step.subheading}
                            </Typography>
                          </Grid>
                          <Grid item>
                            {step.tooltipText ? (
                              <Tooltip title={step.tooltipText}>
                                <div>
                                  <ChunkerButton
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={step.disabled ? null : { backgroundColor: '#f9cc1f' }}
                                    onClick={() => this.redirectPage(step.redirUrl)}
                                    disabled={step.disabled}
                                  >
                                    Show Me
                                  </ChunkerButton>
                                </div>
                              </Tooltip>
                            ) : (
                              <ChunkerButton
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ backgroundColor: '#f9cc1f' }}
                                onClick={() => this.redirectPage(step.redirUrl)}
                                disabled={step.disabled}
                              >
                                Show Me
                              </ChunkerButton>
                            )}
                          </Grid>
                        </Grid>
                      )
                    }
                    return null
                  })}
                </Grid>
              </Hidden>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" spacing={0}>
              <Grid item style={{ marginTop: '8px' }} xs={12} sm={4}>
                <Grid container justifyContent="center" alignItems="center">
                  <ChunkerButton
                    size="small"
                    style={{ color: '#968C83' }}
                    onClick={this.handleTakeMeBack}
                  >
                    Take Me Back To The Start
                  </ChunkerButton>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container justifyContent="center" alignItems="center">
                  <Stepper
                    activeStep={this.state.activeStep}
                    numberOfSteps={steps.length}
                    fnHandleNext={this.handleNextClick}
                    fnHandleBack={this.handleBackClick}
                  />
                </Grid>
              </Grid>
              {!this.props.hideCheckbox && (
                <Grid item xs={12} sm={4}>
                  <Grid container justifyContent="center" alignItems="center">
                    {this.renderCheckbox()}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      )
    }
  }
}

userTab.contextType = UserContext

const mapStateToProps = (state) => ({
  userInfoObject: get(state, ['app', 'userInfoObject']),
})

export default connect(mapStateToProps, { getUserStatus, updateUserInfoObj })(
  withStyles(styles)(userTab),
)
