import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import TermsOfService from './TermsOfService'
import ButtonLikeLink from '../ButtonLikeLink'

import ChunkerButton from '../../utilities/ChunkerButton'
import './terms.css'

/**
 * @description
 * - Renders a dialog that is fit for logic outside of the component.
 * - This is a recreation of TermsDialog during the AUTH refactor that required a new version of the Terms Dialog.
 * During the Signup process the original TermsDialog was required which prompted two different Components.
 */

class TermsDialogBasic extends React.Component {
  constructor() {
    super()
    this.state = {
      openTermsText: false,
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open
          ignoreBackdropClick
          ignoreEscapeKeyUp
          onBackdropClick={() => {
            alert('you need a accept terms and conditions to move forward')
          }}
          maxWidth="lg"
        >
          <DialogTitle>Chunker Terms of Service Acceptance</DialogTitle>
          <DialogContent>
            <DialogContentText>
              By clicking on Agree below, you agree to:
              <ul>
                <li>
                  Accept all the Terms of Service found{' '}
                  <ButtonLikeLink
                    onClick={() =>
                      // log the user out and clear application state.
                      this.props
                        .clearUserSession()
                        .then(() => {
                          window.location.href = '/terms'
                        })
                        .catch(console.error)
                    }
                  >
                    here
                  </ButtonLikeLink>
                  .
                </li>
                <li>
                  To use Chunker for the entire licensing process, including electronic signatures
                  and payment processing.
                </li>
                <li>To pay the Chunker Platform Usage fee if a transaction is completed.</li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <ListItem
            button
            onClick={() =>
              this.setState((prevState) => ({ openTermsText: !prevState.openTermsText }))
            }
          >
            <ListItemText inset primary="View Terms of Service" />
            {this.state.openTermsText ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openTermsText} timeout="auto" unmountOnExit>
            <List disablePadding>
              {/* Do Not Fix Spelling */}
              <div className="TermsDiaol_scrollable-content">
                <TermsOfService />
              </div>
            </List>
          </Collapse>
          <DialogActions>
            <ChunkerButton onClick={this.props.handleDecline} color="primary">
              Disagree
            </ChunkerButton>
            <ChunkerButton onClick={this.props.handleSuccess} color="primary" autoFocus>
              Agree
            </ChunkerButton>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default TermsDialogBasic
